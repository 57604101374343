import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUser,
  faCoffee,
  faPlus,
  faAngleRight,
  faInfoCircle,
  faTimes,
  faBars,
  faBell,
  faPowerOff,
  faAngleLeft,
  faAngleDown,
  faCopy,
  faCheckDouble,
  faCheck,
  faToggleOn,
  faToggleOff,
  faSearch,
  faCar,
  faStar,
  faHome,
  faUtensils,
  faPlane,
  faSkull
} from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

const initFont = () =>
  library.add(
    faCoffee,
    faGoogle,
    faPlus,
    faAngleRight,
    faInfoCircle,
    faTimes,
    faBars,
    faBell,
    faPowerOff,
    faUser,
    faAngleLeft,
    faAngleDown,
    faCopy,
    faCheckDouble,
    faCheck,
    faToggleOn,
    faToggleOff,
    faSearch,
    faCar,
    faStar,
    faHome,
    faUtensils,
    faPlane,
    faSkull
  );

export default initFont;
