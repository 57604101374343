import axios from 'axios';
import { serverUrl } from '../global/Environment';
import { setRuleLoader, setRuleResMsg, setRuleist } from '../actions/ruleActions';

const baseUrl = serverUrl();

/**
 * @desc Get Users rule List
 * @param {Object} obj
 */
export const getRuleList = (phone) => async (dispatch) => {
  dispatch(setRuleLoader(true));
  try {
    const res = await axios.get(`${baseUrl}api/web-ops/user/rules?phone=${phone}`).then((response) => response.data);
    dispatch(setRuleLoader(false));
    const { status, msg, data } = res;
    if (status === 'ok') {
      dispatch(setRuleist(data));
      return data;
    } else {
      dispatch(setRuleist([]));
      dispatch(setRuleResMsg({ error: msg || 'Something goes wrong, please try again' }));
    }
  } catch (error) {
    dispatch(setRuleLoader(false));
    dispatch(setRuleResMsg({ error: error.msg || 'Something goes wrong, please try again' }));
    return error;
  }
};

/**
 * @desc update users rule list
 */
export const updateRules = (phone, ruleList) => async (dispatch) => {
  dispatch(setRuleLoader(true));
  try {
    const res = await axios.post(`${baseUrl}api/web-ops/user/rules`, { phone, rules: ruleList });
    dispatch(setRuleLoader(false));
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setRuleResMsg({ success: msg }));
      return true;
    } else {
      dispatch(setRuleResMsg({ error: msg || 'Something went wrong, please try again' }));
    }
    return false;
  } catch (error) {
    dispatch(setRuleLoader(false));
    dispatch(setRuleResMsg({ error: error.msg || 'Something went wrong, please try again' }));
    return false;
  }
};

/**
 * @desc remove rule from users rule list
 */
export const removeRule = (rule, userId) => async (dispatch) => {
  dispatch(setRuleLoader(true));
  try {
    const res = await axios.post(`${baseUrl}api/web-ops/user/remove-rule`, { rule, userId });
    dispatch(setRuleLoader(false));
    const { status, msg } = res.data;
    if (status === 'ok' && msg === 'Tax Rules updated') {
      dispatch(setRuleResMsg({ success: msg }));
      return true;
    } else if (status === 'ok') {
      return true;
    } else {
      dispatch(setRuleResMsg({ error: msg }));
      return false;
    }
  } catch (e) {
    dispatch(setRuleLoader(false));
    dispatch(setRuleResMsg({ error: e }));
    return false;
  }
};
