import { GET_USERS_RULE_LIST, SET_RULE_MODAL_STATUS, SET_RULE_LOADER, UPDATE_RULE_RES_MSG } from '../../actions/types';

const initialState = {
  ruleList: [],
  loader: false,
  hasRuleModal: false,
  successMsg: '',
  errorMsg: ''
};

export default function ruleReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS_RULE_LIST:
      return {
        ...state,
        ruleList: action.payload
      };
    case SET_RULE_MODAL_STATUS:
      return {
        ...state,
        hasRuleModal: action.payload
      };
    case UPDATE_RULE_RES_MSG:
      return {
        ...state,
        successMsg: action.payload.success,
        errorMsg: action.payload.error
      };
    case SET_RULE_LOADER:
      return {
        ...state,
        loader: action.payload
      };
    default:
      return state;
  }
}
