import { SOCKET_ADD_USER, SOCKET_SET_USERS, SOCKET_REMOVE_USER } from '../../actions/types';

const initialState = {
  users: []
};

export default function socketReducer(state = initialState, action) {
  switch (action.type) {
    case SOCKET_SET_USERS:
      return {
        ...state,
        users: [...new Set(action.payload)]
      };
    case SOCKET_ADD_USER:
      return {
        ...state,
        users: [...new Set([...state.users, action.payload])]
      };
    case SOCKET_REMOVE_USER:
      return {
        ...state,
        users: [...new Set(state.users.filter((userId) => userId !== action.payload))]
      };
    default:
      return state;
  }
}
