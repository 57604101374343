import axios from 'axios';
import { serverUrl } from '../global/Environment';
import { setTagLoader, setTagResMessage, setTags } from '../actions/tagActions';

const baseUrl = serverUrl();

export const fetchTags = () => async (dispatch) => {
  const { status, data: tags } = await axios.get(`${baseUrl}api/web-ops/tag`).then((response) => response.data);
  try {
    if (status === 'ok') {
      dispatch(setTags(tags));
    } else {
      dispatch(setTags([]));
    }
  } catch (e) {
    dispatch(setTags([]));
  }
};

export const updateTags = (updatedTags, deletedTags) => async (dispatch) => {
  dispatch(setTagLoader(true));
  try {
    const { status, msg, data: tags } = await axios
      .post(`${baseUrl}api/web-ops/tag`, { updatedTags, deletedTags })
      .then((response) => response.data);
    dispatch(setTagLoader(false));

    if (status === 'ok') {
      dispatch(setTags(tags));
      dispatch(setTagResMessage({ success: msg }));
    } else {
      dispatch(setTagResMessage({ error: msg }));
    }
  } catch (e) {
    dispatch(setTagLoader(false));
    dispatch(setTagResMessage({ error: e }));
  }
};
