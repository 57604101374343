import axios from 'axios';
import { serverUrl } from '../global/Environment';
import {
  setUserInfo,
  setUserList,
  setNoMoreBlueDots,
  setMaxReached,
  setNumBlueDots,
  setOutstandingBlue,
  setUserSearchTags,
  setUserLoading
} from '../actions/userActions';
import { setBankAccountList, setBankAccountLoader } from '../actions/bankAccountActions';
import store from '../store/store';
import _ from 'lodash';
import { addSocketUser } from '../actions/socketActions';
import { clearExpenseMapping, clearProbLength } from '../actions/chatActions';
import { resetNumExpenseUpdates, setSavings } from '../actions/expenseActions';
import { getChatMessageList } from './messageService';
import { fetchUserExpenseList, getSavings } from './expenseService';
import { clearChatReducer } from '../actions/authActions';

const baseUrl = serverUrl();

/**
 * @desc Get All Users List
 * @param {Object} obj
 */
export const getUserList = (obj) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}api/web-ops/user/getUserList`, obj)
      .then((response) => response.data)
      .then((res) => {
        const { status, msg } = res;
        if (status === 'ok') {
          const { data } = res;
          const { mappedUsers, numBlueDots } = data;
          dispatch(setNumBlueDots(numBlueDots));
          resolve({ data: mappedUsers });
        } else {
          reject(msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @desc Get All Users List
 * @param {Object} obj
 */
export const addBlueDots = () => async (dispatch) => {
  const userList = await axios.get(`${baseUrl}api/web-ops/user/add-blue-dots`);
  const users = _.get(userList, ['data', 'data', 'mappedUsers']);
  const newAssignments = _.get(userList, ['data', 'data', 'newAssignments']);
  const newAssignmentsUserIds = newAssignments.map(({ user_id }) => user_id);
  for (const userId of newAssignmentsUserIds) {
    dispatch(addSocketUser(userId));
  }
  const maxReached = _.get(userList, ['data', 'data', 'maxReached']);
  const outstandingBlue = _.get(userList, ['data', 'data', 'outstandingBlue']);
  dispatch(setUserList(users));
  if (newAssignments.length === 0 && !maxReached) {
    dispatch(setNoMoreBlueDots(true));
  } else {
    dispatch(setNoMoreBlueDots(false));
  }
  dispatch(setMaxReached(maxReached));
  dispatch(setOutstandingBlue(outstandingBlue));
};

/**
 * @desc Get Users Information
 * @param {Object} obj
 */
export const getUsersInfo = (obj) => () => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}api/web-ops/user/getUserInfo`, obj)
      .then((response) => response.data)
      .then((res) => {
        const { status, msg } = res;
        if (status === 'ok') {
          const { data } = res;
          resolve(data);
        } else {
          reject(msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const clearSelectedUserInformation = () => async (dispatch) => {
  dispatch(setUserInfo({}));
  dispatch(clearChatReducer());
  dispatch(clearExpenseMapping());
  dispatch(clearProbLength());
  dispatch(resetNumExpenseUpdates());
};

export const selectUser = (userPhone) => async (dispatch) => {
  dispatch(setUserLoading(true));
  try {
    const memberObj = { phone: userPhone };
    const memberinfo = await getUsersInfo(memberObj)(dispatch);
    dispatch(setUserInfo(memberinfo));
    // Get Chat Messages
    dispatch(getChatMessageList(memberinfo.id, () => dispatch(fetchUserExpenseList(userPhone))));

    const savingsResponse = await getSavings(memberinfo.id);
    const savings = _.get(savingsResponse, ['data', 'savings']);
    dispatch(setSavings(savings));
    dispatch(getUsersBankList({ userId: memberinfo.id }));
  } catch (e) {
    console.log(e);
    dispatch(clearSelectedUserInformation());
  }
  dispatch(setUserLoading(false));
};

export const filterUsersBySearchTags = (searchTags) => async (dispatch) => {
  dispatch(setUserLoading(true));
  try {
    const userList = await getUserList({ searchTags })(dispatch);
    dispatch(setUserList(userList.data));
    const firstMember = userList.data[0];
    if (firstMember) {
      dispatch(selectUser(firstMember.phone));
    } else {
      dispatch(clearSelectedUserInformation());
    }
    dispatch(setUserSearchTags(searchTags));
  } catch (e) {
    console.error(e);
  }
  dispatch(setUserLoading(false));
};

/**
 * @desc Get Un noticed Users List
 */
export const getUnnoticedUsers = () => () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}api/web-ops/user/getUnnoticedUsers`)
      .then((response) => response.data)
      .then((res) => {
        const { status, msg } = res;
        if (status === 'ok') {
          const { data } = res;
          resolve(data);
        } else {
          reject(msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateUserTags = ({ tagIds, userId, userPhone }) => async (dispatch) => {
  const { data: res } = await axios.post(`${baseUrl}api/web-ops/user/update-user-tags`, {
    tag_ids: tagIds,
    user_id: userId
  });
  if (res.status === 'ok') {
    // User-tag associations are in the user info. Therefore, we re-fetch the user info and update it in state, as it has changed
    const user = await getUsersInfo({ phone: userPhone })(dispatch);
    store.dispatch(setUserInfo(user));
    return res;
  }
  throw Error(res.msg);
};

/**
 * @desc Set Auto Predict
 * @param {Object} obj
 */
export const setAutoPredict = (obj) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}api/web-ops/user/set-auto-predict`, obj)
      .then((response) => response.data)
      .then((res) => {
        const { status, msg } = res;
        if (status === 'ok') {
          resolve(res);
          // Update redux store
          let memberInfo = store.getState().user.memberInfo;
          memberInfo.autoPredict = obj.autoPredict;
          dispatch(setUserInfo(memberInfo));
        } else {
          reject(msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @desc Cancel User
 * @param {Object} obj
 */
export const cancelUser = (obj) => () => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}api/web-ops/user/cancel-user`, obj)
      .then((response) => response.data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @desc Get Users Bank List
 * @param {Object} obj
 */
export const getUsersBankList = ({ userId }) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}api/web-ops/user/bank/list/${userId}`)
      .then((response) => response.data)
      .then((res) => {
        const { status, msg, data } = res;
        if (status === 'ok') {
          dispatch(setBankAccountList(data));
          dispatch(setBankAccountLoader(false));
          resolve(data);
        } else {
          reject(msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @desc Remove Bank Account of the user
 * @param {Object} obj
 */
export const removeBankAccount = ({ itemId }) => () => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrl}api/web-ops/user/bank/remove/${itemId}`)
      .then((response) => response.data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @desc
 * @param {Object} obj
 */
export const updateSurveyResponse = ({ surveyId, surveyResponse }) => async () => {
  const response = await axios.patch(`${baseUrl}api/web-ops/user/update-survey-response/${surveyId}`, {
    response: surveyResponse
  });

  const {
    data: { status },
    data
  } = response;

  if (status === 'ok') {
    return data;
  } else {
    throw Error(data.msg);
  }
};

export const createImpersonationLink = ({ impersonateeId }) => async () => {
  const response = await axios.post(`${baseUrl}api/web-ops/user/create-impersonation-link`, {
    impersonateeId
  });

  if (_.get(response, ['data', 'status']) !== 'ok') {
    throw new Error(_.get(response, ['data', 'msg']));
  }

  window.open(_.get(response, ['data', 'data', 'link']));
};

/**
 * @desc Set Hold
 * @param {Object} obj
 */
export const setHold = ({ id, isOnHold, holdingBookkeeperId }) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}api/web-ops/user/set-hold`, { id, isOnHold, holdingBookkeeperId })
      .then((response) => response.data)
      .then((res) => {
        const { status, msg } = res;
        if (status === 'ok') {
          resolve(res);
          // Update redux store for memberInfo
          const updatedMemberInfo = {
            ...getState().user.memberInfo,
            isOnHold: isOnHold,
            holdingBookkeeperId: holdingBookkeeperId
          };
          dispatch(setUserInfo(updatedMemberInfo));

          // Update redux store for memberList
          const memberList = getState().user.memberList;
          const updatedMemberList = memberList.map((m) => {
            if (m.userId === updatedMemberInfo.id) {
              m.isOnHold = isOnHold;
              m.holdingBookkeeperId = holdingBookkeeperId;
            }
            return m;
          });
          dispatch(setUserList(updatedMemberList));
        } else {
          reject(msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
