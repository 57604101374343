import {
  GET_USERS_MESSAGE_LIST,
  MESSAGES_LOADING,
  CHAT_SCROLL,
  ADD_NEW_MESSAGE,
  UPDATE_EXPENSE_MAPPING,
  UPDATE_PROB_LENGTH,
  CLEAR_EXPENSE_MAPPING,
  CLEAR_PROB_LENGTH,
  UPDATE_MESSAGE,
  SET_DATA_MISTAKES_MODAL_STATUS,
  SET_DATA_MISTAKE_TYPES,
  IMPORT_TO_QDECK_MODAL_STATUS
} from './types';
import store from '../store/store';
import _ from 'lodash';

/**
 * @desc Set Users message list dispatch service
 * @param {*} users
 */
export const setMessageList = (messages) => {
  const [processedMessages, unprocessedMessages] = _.partition(messages, 'id');

  const sortedUnprocessedMessages = _.sortBy(unprocessedMessages, 'time');

  const sortedMessages = _.chain(processedMessages)
    .sortBy('id')
    .reduce((acc, processedMessage) => {
      if (_.isEmpty(sortedUnprocessedMessages)) {
        return [...acc, processedMessage];
      }

      if (processedMessage.time < sortedUnprocessedMessages[0].time) {
        return [...acc, processedMessage];
      }

      const unprocessedMessage = sortedUnprocessedMessages.shift();

      return [...acc, unprocessedMessage, processedMessage];
    }, [])
    .slice(-200)
    .value();

  return {
    type: GET_USERS_MESSAGE_LIST,
    payload: sortedMessages
  };
};

/**
 * @desc Set messages loading
 */
export const setMessageLoading = (payload) => {
  return {
    type: MESSAGES_LOADING,
    payload
  };
};

/**
 * @desc Set Chat Scroll
 */
export const setChatScroll = (payload) => {
  return {
    type: CHAT_SCROLL,
    payload
  };
};

export const updateExpenseMapping = (payload) => {
  return {
    type: UPDATE_EXPENSE_MAPPING,
    payload
  };
};

export const updateProbLength = (payload) => {
  return {
    type: UPDATE_PROB_LENGTH,
    payload
  };
};

export const clearExpenseMapping = () => {
  return {
    type: CLEAR_EXPENSE_MAPPING
  };
};

export const clearProbLength = () => {
  return {
    type: CLEAR_PROB_LENGTH
  };
};

/**
 * @desc Add new Message
 * @param {*} obj
 */
export const addNewMessage = (obj) => {
  let messageList = store.getState().chat.messageList;
  const userPhone = store.getState().user.memberInfo.phone;
  const userId = store.getState().user.memberInfo.id;
  const { from, to } = obj;
  if (userPhone === from || Number(userId) === Number(from)) {
    obj.className = 'user';
  }
  if (userPhone === from || userPhone === to || Number(userId) === Number(from) || Number(userId) === Number(to)) {
    messageList.push(obj);
  }
  return {
    type: ADD_NEW_MESSAGE,
    payload: messageList
  };
};

export const scrollToMessage = (obj) => {
  const userPhone = store.getState().user.memberInfo.phone;
  const userId = store.getState().user.memberInfo.id;
  const { from, to } = obj;
  if (userPhone === from || userPhone === to || Number(userId) === Number(from) || Number(userId) === Number(to)) {
    return {
      type: CHAT_SCROLL,
      payload: { scroll: true, style: 'smooth' }
    };
  } else {
    return {
      type: CHAT_SCROLL,
      payload: { scroll: false, style: 'auto' }
    };
  }
};

export const updateMessage = (id, message) => {
  return {
    type: UPDATE_MESSAGE,
    payload: {
      id,
      message
    }
  };
};

export const setDataMistakesModalStatus = ({ open, message }) => {
  return {
    type: SET_DATA_MISTAKES_MODAL_STATUS,
    payload: { open, message }
  };
};

export const setDataMistakeTypes = (dataMistakeTypes) => {
  return {
    type: SET_DATA_MISTAKE_TYPES,
    payload: dataMistakeTypes
  };
};

export const setImportToQDeckModalStatus = ({ open, message }) => {
  return {
    type: IMPORT_TO_QDECK_MODAL_STATUS,
    payload: { open, message }
  };
};
