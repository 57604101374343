import io from 'socket.io-client';
import _ from 'lodash';
import { addNewMessage, scrollToMessage } from '../actions/chatActions';
import { addSocketUser, removeSocketUser } from '../actions/socketActions';
import { GET_USERS_LIST, SOCKET_CONNECT, SOCKET_DISCONNECT } from '../actions/types';
import { serverUrl, TWILLIO_PHONES_PLUS_STREAM } from '../global/Environment';
import { getUsersInfo } from '../services/userService';
import * as Sentry from '@sentry/browser';
import { setUserInfo } from '../actions/userActions';

export const createSocketMiddleware = () => (store) => {
  let socket = null;

  return (next) => (action) => {
    switch (action.type) {
      case SOCKET_CONNECT:
        if (socket) {
          socket.disconnect();
        }
        socket = createSocket(store);
        return;
      case SOCKET_DISCONNECT:
        if (socket) {
          socket.disconnect();
        }
        socket = null;
        return;
      // Join socket rooms when the user list in SideMenu is updated
      case GET_USERS_LIST:
        if (socket) {
          socket.emit('set chat rooms', _.map(action.payload, 'userId'));
        }
        return next(action);
      default:
        return next(action);
    }
  };
};

export const createSocket = (store) => {
  const socket = io(`${serverUrl()}webops`, {
    transports: ['websocket'],
    auth: (cb) => {
      cb({
        token: localStorage.getItem('KeeperToken')
      });
    }
  });

  socket.io.on('open', () => {
    console.log(`${new Date()}: socket connecting`);
    socket.emit('set chat rooms', _.map(store.getState().user.memberList, 'userId'));
  });

  socket.io.on('error', (e) => {
    console.error('socket encountered error: ', e.message, 'Closing socket');
    socket.close();
  });

  socket.io.on('close', (reason) => {
    console.log(`${new Date()}: socket disconnected -- ${reason}`);
  });

  socket.on('connect_error', (error) => {
    console.error('There was an error connecting the socket: ', error.message);
    Sentry.captureException(error);
  });

  socket.on('message', async (action) => {
    if (action.type === 'SEND_MESSAGE') {
      const payload = _.get(action, 'payload');

      const selectedUserId = _.get(store.getState(), ['user', 'memberInfo', 'id']);

      const messageUserId = _.get(payload, 'user_id');

      const unifiedChatEnabled = _.get(store.getState(), ['user', 'memberInfo', 'unifiedChatEnabled']);
      const isUserMessage = !TWILLIO_PHONES_PLUS_STREAM.has(_.get(payload, 'from'));

      if (unifiedChatEnabled) {
        if (!isUserMessage && payload.className === 'notification - escalation') {
          store.dispatch(addSocketUser(messageUserId));
        } else if (!isUserMessage) {
          store.dispatch(removeSocketUser(messageUserId));
        }
      } else {
        if (isUserMessage) {
          store.dispatch(addSocketUser(messageUserId));
        } else {
          store.dispatch(removeSocketUser(messageUserId));
        }
      }

      if (selectedUserId === messageUserId) {
        store.dispatch(addNewMessage(payload));
        store.dispatch(scrollToMessage(payload));
      } else {
        console.log('Ignoring incoming message');
      }
    } else if (action.type === 'UPDATE_USER_TAGS') {
      const userPhone = _.get(store.getState(), ['user', 'memberInfo', 'phone']);
      const user = await getUsersInfo({ phone: userPhone })(store.dispatch);
      store.dispatch(setUserInfo(user));
    }
  });

  return socket;
};
