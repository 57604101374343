// src/socket.js
import { SOCKET_ADD_USER, SOCKET_CONNECT, SOCKET_DISCONNECT, SOCKET_REMOVE_USER, SOCKET_SET_USERS } from './types';

/**
 * @desc Add user id
 * @param {Number} userId
 */
export const addSocketUser = (userId) => {
  return {
    type: SOCKET_ADD_USER,
    payload: userId
  };
};

/**
 * @desc Remove user id
 * @param {Number} userId
 */
export const removeSocketUser = (userId) => {
  return {
    type: SOCKET_REMOVE_USER,
    payload: userId
  };
};

/**
 * @desc Set array of user ids
 * @param {Array} userIds
 */
export const setSocketUsers = (userIds) => {
  return {
    type: SOCKET_SET_USERS,
    payload: userIds
  };
};

/**
 * @desc Connect to the socket server
 * @param {Array} userIds
 */
export const connectSocket = () => {
  return {
    type: SOCKET_CONNECT
  };
};

export const disconnectSocket = () => {
  return {
    type: SOCKET_DISCONNECT
  };
};
