import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './MonitoredAccounts.scss';
import { removeBankAccount } from '../../../../../services/userService';
import { setBankAccountList, setBankAccountLoader } from '../../../../../actions/bankAccountActions';
import { Loader } from '../../../../Common';
import Swal from 'sweetalert2';

class MonitoredAccounts extends Component {
  removeBankAccount = async (e, item_id) => {
    e.preventDefault();
    const { accountList } = this.props.bankAccount;
    Swal.fire({
      title: 'Are you sure?',
      text: 'This account will be removed!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then(async (result) => {
      if (result.value) {
        try {
          this.props.setBankAccountLoader(true);
          const removeResponse = await this.props.removeBankAccount({
            itemId: item_id
          });
          const { status, msg } = removeResponse;
          if (status === 'ok') {
            Swal.fire('Success!', msg, 'success');
            // Remove account from list
            const newAccountList = accountList.filter((a) => a.item_id !== item_id);
            this.props.setBankAccountList(newAccountList);
            this.props.setBankAccountLoader(false);
          } else {
            Swal.fire({ type: 'error', title: 'Oops...', text: msg });
          }
        } catch (e) {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Error occurred'
          });
        }
      }
    });
  };

  getStringFromExpenseType(type) {
    if (type === 'work only') {
      return '(W)';
    } else if (type === 'personal only') {
      return '(P)';
    } else {
      return '';
    }
  }

  render() {
    const { accountList, loader } = this.props.bankAccount;
    return (
      <div className='monitoredAccounts'>
        <h6>Monitored Accounts</h6>
        {loader && (
          <div className='accountLoading'>
            <Loader />
          </div>
        )}
        {!loader && (
          <div className='accountList'>
            {accountList.length === 0 && <p>Bank accounts not available</p>}
            {accountList.length === undefined && <p>Bank accounts not available</p>}

            {accountList.length > 0 &&
              accountList.map((data, index) => (
                <div className='listItem' key={index}>
                  <span className='itemName'>{data.institution_name}</span>
                  <span className={data.linked ? 'itemStatus linked' : 'itemStatus notLinked'}>
                    {data.linked ? 'Linked' : 'Unlinked'}
                  </span>
                  <span className='itemAction'>
                    <button type='button' onClick={(e) => this.removeBankAccount(e, data.item_id)}>
                      <FontAwesomeIcon icon={['fa', 'times']} />
                    </button>
                  </span>
                  <div className='subItems'>
                    {data.subAccounts.map((sa, saKey) => (
                      <div className='subItem' key={saKey}>
                        <p>
                          {sa.name} xx-{sa.mask} <span>{this.getStringFromExpenseType(sa.expense_type)}</span>
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }
}

MonitoredAccounts.propTypes = {
  bankAccount: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  bankAccount: state.bankAccount
});

const ConnectedMonitoredAccounts = connect(mapStateToProps, {
  removeBankAccount,
  setBankAccountList,
  setBankAccountLoader
})(MonitoredAccounts);

export default ConnectedMonitoredAccounts;
