import React from 'react';
import { connect } from 'react-redux';
import './Macros.scss';
import { getMacroList } from '../../../../../services/macroService';
import { setMacroModalStatus } from '../../../../../actions/macroActions';
import Fuse from 'fuse.js';
import { trackActivity } from '../../../../../utils/analytics/analyticsService';

class Macros extends React.Component {
  state = {
    filteredMacros: [],
    allMacros: []
  };

  async componentDidMount() {
    const { data } = await getMacroList();
    const { macros } = data.data;
    this.setState({ filteredMacros: macros, allMacros: macros });
  }

  onInputChange = (e) => {
    const { value } = e.target;
    if (value === '') {
      this.setState({ macros: this.state.allMacros });
    } else {
      const { allMacros } = this.state;
      const fuse = new Fuse(allMacros, { keys: ['name'] });

      this.setState({
        filteredMacros: fuse.search(value).map(({ item }) => item)
      });
    }
  };

  render() {
    const {
      setMacroMessage,
      user: { memberInfo }
    } = this.props;
    const { filteredMacros } = this.state;
    return (
      <div className='macros'>
        <input
          autoFocus
          name='Search Macros'
          className='search-input'
          type='text'
          placeholder='Search macros'
          onChange={this.onInputChange}
        />
        <h3>Macros</h3>
        <div className='macro-item'>
          <div className='name-column'>Name</div>
          <div className='text-column'>Text</div>
        </div>
        {filteredMacros.length ? (
          filteredMacros.map(({ name, text, id }) => (
            <button
              key={id}
              className='macro-item'
              onClick={() => {
                setMacroMessage(text);
                trackActivity('used macro', {
                  macroId: id,
                  macroName: name,
                  macroText: text,
                  userPhone: memberInfo.phone
                });
                this.props.setMacroModalStatus(false);
              }}>
              <div className='name-column'>{name.length > 100 ? name.slice(0, 100) + '...' : name}</div>
              <div className='text-column'>{text}</div>
            </button>
          ))
        ) : (
          <p>No macros match your search, please try another search</p>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

const ConnectedMacros = connect(mapStateToProps, { setMacroModalStatus })(Macros);

export default ConnectedMacros;
