import React, { Component } from 'react';
import SideMenu from './SideMenu';
import DashboardContent from './DashboardContent';
import './Dashboard.scss';

class Dashboard extends Component {
  render() {
    return (
      <div className='dashboard'>
        <SideMenu />
        <DashboardContent />
      </div>
    );
  }
}

export default Dashboard;
