import { SET_MACRO_MODAL_STATUS } from '../../actions/types';
const initialState = {
  hasMacroModal: false
};

export default function macroReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MACRO_MODAL_STATUS:
      return {
        ...state,
        hasMacroModal: action.payload
      };
    default:
      return state;
  }
}
