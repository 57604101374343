import React from 'react';
import { connect } from 'react-redux';
import { Modal } from '../../../Common';
import Macros from './Macros/Macros.jsx';
import { setMacroModalStatus } from '../../../../actions/macroActions';

function MacrosSection({ macro, setMacroMessage, ...props }) {
  const { hasMacroModal } = macro;

  return hasMacroModal ? (
    <Modal
      open={hasMacroModal}
      size='big'
      handleClose={() => {
        props.setMacroModalStatus(false);
      }}>
      <Macros setMacroMessage={setMacroMessage} />
    </Modal>
  ) : null;
}

const mapStateToProps = (state) => ({
  macro: state.macro
});

const ConnectedMacrosSection = connect(mapStateToProps, { setMacroModalStatus })(MacrosSection);

export default ConnectedMacrosSection;
