import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getSignedUrl } from '../../../../../services/messageService';

const ChatAttachment = ({ userId, attachment: { key, type, filename }, getSignedUrl }) => {
  const [signedUrlResult, setSignedUrlResult] = useState({});

  const { signedUrl, thumbnailSignedUrl } = signedUrlResult;

  useEffect(() => {
    (async () => {
      if (!type.startsWith('file')) {
        return;
      }

      const result = await getSignedUrl({ userId, key });

      setSignedUrlResult(result);
    })();
  }, [getSignedUrl, key, type, userId]);

  if (type === 'file/image') {
    return (
      <a
        href={signedUrl}
        target='_blank'
        rel='noopener noreferrer'
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}>
        <img src={thumbnailSignedUrl} alt={filename} height={150} />
        {filename}
      </a>
    );
  }
  if (type === 'file/pdf') {
    return (
      <a href={signedUrl} target='_blank' rel='noopener noreferrer'>
        {filename}
      </a>
    );
  }

  return null;
};

const mapDispatchToProps = {
  getSignedUrl
};

const ConnectedChatAttachment = connect(null, mapDispatchToProps)(ChatAttachment);

export default ConnectedChatAttachment;
