import React from 'react';
import { useChatFiles } from '../../../../services/messageService';
import { DataGrid } from '@mui/x-data-grid';
import { LinearProgress } from '@mui/material';

const ChatFileSection = () => {
  const { files, error, loading } = useChatFiles();

  if (error) {
    return <div>{error.message}</div>;
  }

  const columns = [
    {
      field: 'createdAt',
      headerName: 'Upload Date',
      type: 'dateTime',
      valueGetter: (_value, file) => file.createdAt && new Date(file.createdAt)
    },
    {
      field: 'file_name',
      headerName: 'Filename'
    },
    {
      field: 'signedUrl',
      headerName: 'Link',
      renderCell: (params) => {
        const file = params.row;

        return (
          <a href={file.signedUrl} target='_blank' rel='noopener noreferrer'>
            {file.thumbnailSignedUrl ? <img src={file.thumbnailSignedUrl} alt={file.file_name} height={50} /> : 'Link'}
          </a>
        );
      }
    }
  ];

  return (
    <DataGrid
      rows={files}
      slots={{
        loadingOverlay: LinearProgress
      }}
      columns={columns}
      loading={loading}
      autosizeOnMount
      autoPageSize
    />
  );
};

export default ChatFileSection;
