import { SET_BANK_ACCOUNT_LIST, SET_BANK_ACCOUNT_LOADER } from './types';

/**
 * @desc Set Bank Account List
 * @param {*} obj
 */
export const setBankAccountList = (payload) => {
  return {
    type: SET_BANK_ACCOUNT_LIST,
    payload
  };
};

/**
 * @desc Set Bank Account Loader
 * @param {*} obj
 */
export const setBankAccountLoader = (payload) => {
  return {
    type: SET_BANK_ACCOUNT_LOADER,
    payload
  };
};
