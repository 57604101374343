import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Lines from './Lines.jsx';
import './DataMistakesSection.scss';
import { getDataMistakeTypes, recordDataMistake } from '../../../../../services/messageService';
import Select from 'react-select';
import _ from 'lodash';
import { setDataMistakesModalStatus } from '../../../../../actions/chatActions';
import { Loader } from '../../../../Common';

// Max comment size in db
const MAX_COMMENT_LENGTH = 2000;

const DataMistakesSection = ({
  dataMistakeTypes,
  dataMistakesMessage,
  getDataMistakeTypes,
  recordDataMistake,
  setDataMistakesModalStatus
}) => {
  const [dataMistakeType, setDataMistakeType] = useState(null);
  const [dataMistakeCategory, setDataMistakeCategory] = useState(undefined);
  const [comments, setComments] = useState('');
  const [validationText, setValidationText] = useState(undefined);
  const [sendingDataMistake, setSendingDataMistake] = useState(false);

  const categorySelectOptions = dataMistakeTypes
    .filter((mistakeType) => mistakeType.category === dataMistakeCategory)
    .map((mistakeType) => ({
      ...mistakeType,
      value: mistakeType.id,
      label: mistakeType.name
    }));

  useEffect(() => {
    if (dataMistakeTypes.length === 0) {
      getDataMistakeTypes();
    }
  }, [dataMistakeTypes.length, getDataMistakeTypes]);

  useEffect(() => {
    if (dataMistakesMessage?.textType === 'assistant - response') {
      setDataMistakeCategory('ai');
    } else {
      setDataMistakeCategory('write-off');
    }
  }, [dataMistakesMessage]);

  const onSelectDataMistakeType = (dataMistakeType) => {
    setDataMistakeType(dataMistakeType);
    if (validationText) {
      setValidationText(undefined);
    }
  };

  const onSuggestedMessageChange = (e) => {
    setComments(e.target.value);
    if (validationText) {
      setValidationText(undefined);
    }
  };

  const onSubmit = async () => {
    if (!dataMistakeType) {
      setValidationText('A data mistake type must be provided');
      return;
    }
    if (_.isEmpty(comments)) {
      setValidationText('A comment must be provided');
      return;
    }
    if (comments.length > MAX_COMMENT_LENGTH) {
      setValidationText(
        `Comment must be ${MAX_COMMENT_LENGTH} chars or shorter. Current length is ${comments.length} chars`
      );
      return;
    }

    setValidationText(undefined);
    setSendingDataMistake(true);
    const body = {
      mistake_type: dataMistakeType.id,
      sms_id: dataMistakesMessage.id,
      comments
    };
    await recordDataMistake(body);
    setSendingDataMistake(false);
    setDataMistakesModalStatus({ open: false });
    setDataMistakeType(null);
    setComments('');
  };

  return (
    <>
      <div className='modal-title'>
        <h5>Report a Data Mistake 🐛🐛🐛</h5>
      </div>
      <div className='mistakes-modal-body'>
        <div className='mistakes-modal-row'>
          <div className='mistakes-modal-row-name'>Response</div>
          <Lines className='mistakes-modal-message' body={dataMistakesMessage?.body} />
        </div>
        <div className='mistakes-modal-row'>
          <div className='mistakes-modal-row-name'>Mistake Type</div>
          <Select
            className='mistakes-modal-type-select'
            options={categorySelectOptions}
            value={dataMistakeType}
            backspaceRemovesValue={false}
            onChange={onSelectDataMistakeType}
          />
        </div>
        <div className='mistakes-modal-row'>
          <div className='mistakes-modal-row-name'>Comments / Suggestions</div>
          <textarea
            name='suggested-message'
            className='mistakes-modal-suggested-message'
            onChange={onSuggestedMessageChange}
            value={comments}
          />
        </div>
      </div>
      <div className='mistakes-modal-footer'>
        <div className='mistakes-modal-validation'>{validationText}</div>
        {!sendingDataMistake && (
          <button className='btn blue' type='submit' onClick={onSubmit} disabled={sendingDataMistake || validationText}>
            Submit
          </button>
        )}
        {sendingDataMistake && <Loader />}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  dataMistakeTypes: state.chat.dataMistakeTypes,
  dataMistakesMessage: state.chat.dataMistakesMessage
});

const ConnectedDataMistakesSection = connect(mapStateToProps, {
  getDataMistakeTypes,
  recordDataMistake,
  setDataMistakesModalStatus
})(DataMistakesSection);

export default ConnectedDataMistakesSection;
