import { _ } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../../actions/authActions';
import { setSocketUsers } from '../../../actions/socketActions';
import { setUserInfo, setUserList, setUserLoading, setUserSearchTags } from '../../../actions/userActions';
import BlueDotBtn from './BlueDotBtn.jsx';
import { getUnnoticedUsers, getUserList, getUsersInfo, selectUser } from '../../../services/userService';
import { Loader } from '../../Common';
import { faPause } from '@fortawesome/free-solid-svg-icons';
import './SideMenu.scss';

class SideMenu extends Component {
  state = {
    sortOrder: 'ASC',
    sortBy: '',
    searchText: '',
    filterLoader: false
  };

  async loadUsers(searchTags) {
    // Get User Notification Function
    this.getUserNotification();
    this.props.setUserLoading(true);
    const userList = await this.props.getUserList({ searchTags });
    this.props.setUserList(userList.data);

    // Get info for first user
    const firstMember = userList.data[0];
    if (firstMember) {
      this.props.selectUser(firstMember.phone);
    } else {
      this.props.setUserList([]);
      this.props.setUserInfo({});
    }
    this.props.setUserLoading(false);
  }

  async componentDidMount() {
    await this.loadUsers();
  }

  /**
   * @description On Member Click
   * @param {String} phone
   */
  async onMemberClick(phone) {
    this.props.selectUser(phone);
  }

  /**
   * @desc This function will give array of user Id who doesn't got response
   */
  async getUserNotification() {
    try {
      const userList = await this.props.getUnnoticedUsers();
      this.props.setSocketUsers(userList);
    } catch (e) {
      console.log(e);
      this.setState({ unNoticedUsers: [] });
    }
  }

  /**
   * @description Logout User
   */
  onLogout = () => {
    this.props.logout();
  };

  /**
   * @description On change event
   */
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /**
   * @description on search user click
   * @param {String} type
   */
  async searchUser(e) {
    e.preventDefault();
    // Don't allow for keyword and tag search at the same time
    this.props.setUserSearchTags(undefined);
    this.getUserList();
  }

  /**
   * @description Get User List
   */
  async getUserList() {
    const { sortOrder, sortBy, searchType, searchText } = this.state;
    try {
      const userListObj = {
        searchText,
        sortBy,
        sortOrder,
        searchType
      };
      this.setState({ filterLoader: true });
      // Getting user list
      const userList = await this.props.getUserList(userListObj);
      // Set user list
      this.props.setUserList(userList.data);
      this.setState({ filterLoader: false });
    } catch (e) {
      this.setState({ filterLoader: false });
      console.log(e);
    }
  }

  render() {
    const { filterLoader, searchText } = this.state;
    const loading = this.props.user.loading;
    const { memberList, memberInfo } = this.props.user;
    const { user } = this.props.auth;
    const unNoticedUsersList = this.props.socket.users;

    const holdQueue = _.filter(
      memberList,
      ({ isOnHold, holdingBookkeeperId }) => isOnHold && holdingBookkeeperId == user.id
    );
    const highPriorityQueue = _.filter(
      memberList,
      ({ priority, isOnHold, holdingBookkeeperId }) => priority && !(isOnHold && holdingBookkeeperId == user.id)
    );
    const normalPriorityQueue = _.filter(
      memberList,
      ({ priority, isOnHold, holdingBookkeeperId }) => !priority && !(isOnHold && holdingBookkeeperId == user.id)
    );

    return (
      <>
        {loading && <Loader type='page' />}
        <div className='sideMenu'>
          {filterLoader && (
            <div className='userListLoader'>
              <Loader name='bars' />
            </div>
          )}
          <div className='userControls'>
            <div className='userInfo'>
              <span>
                <FontAwesomeIcon icon={['fa', 'user']} /> {user.firstName} {user.lastname}{' '}
              </span>
              <button className='logout' onClick={this.onLogout}>
                <FontAwesomeIcon icon={['fa', 'power-off']} />
              </button>
            </div>
          </div>
          {!user.isExternalAccountant && (
            <div className='filters'>
              <form onSubmit={(e) => this.searchUser(e)}>
                <div className='phoneFilter'>
                  <div className='phoneText'>
                    <input
                      type='text'
                      name='searchText'
                      value={searchText}
                      onChange={this.onChange}
                      placeholder='Id, phone, or email'
                      autoComplete='off'
                      maxLength='100'
                    />
                  </div>
                  <div className='phoneBtn'>
                    <button type='button' onClick={(e) => this.searchUser(e)}>
                      <FontAwesomeIcon icon={['fa', 'search']} />
                    </button>
                  </div>
                </div>
              </form>
              <BlueDotBtn loadUsers={this.loadUsers.bind(this)} />
            </div>
          )}
          <div className='memberList'>
            {memberList.length === 0 && (
              <div className='memberNotFound'>
                <p>
                  No users here{' '}
                  <span role='img' aria-label='hmm'>
                    🤔
                  </span>
                </p>
              </div>
            )}
            {holdQueue.length > 0 && (
              <>
                <div className='hold-separator'>Hold</div>
                <UserButton
                  users={holdQueue}
                  unNoticedUsersList={unNoticedUsersList}
                  memberInfo={memberInfo}
                  onMemberClick={this.onMemberClick.bind(this)}
                  bookkeeper={user}></UserButton>
              </>
            )}
            {highPriorityQueue.length > 0 && (
              <>
                <div className='priority-separator'>Priority</div>
                <UserButton
                  users={highPriorityQueue}
                  unNoticedUsersList={unNoticedUsersList}
                  memberInfo={memberInfo}
                  onMemberClick={this.onMemberClick.bind(this)}
                  bookkeeper={user}></UserButton>
              </>
            )}
            {normalPriorityQueue.length > 0 && (
              <>
                <div className='priority-separator non-priority-separator'>Normal Priority</div>
                <UserButton
                  users={normalPriorityQueue}
                  unNoticedUsersList={unNoticedUsersList}
                  memberInfo={memberInfo}
                  onMemberClick={this.onMemberClick.bind(this)}
                  bookkeeper={user}></UserButton>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

const UserButton = ({ users, unNoticedUsersList, memberInfo, onMemberClick, bookkeeper }) => {
  return (
    <>
      {users.map((user, idx) => (
        <div className='listItem' key={idx}>
          <button
            type='button'
            className={memberInfo.id === user.userId ? 'itemButton active' : 'itemButton'}
            onClick={() => onMemberClick(user.phone)}>
            <span className='itemText'>
              {user.firstName} {!user.autoPredict ? '(M)' : ''}
            </span>
            {unNoticedUsersList.indexOf(user.userId) !== -1 && <span className='itemNotification' />}
            {user.isOnHold && user.holdingBookkeeperId !== bookkeeper.id && (
              <FontAwesomeIcon className='itemHoldIcon' icon={faPause} />
            )}
          </button>
        </div>
      ))}
    </>
  );
};

SideMenu.propTypes = {
  getUserList: PropTypes.func.isRequired,
  getUsersInfo: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  expense: state.expense,
  chat: state.chat,
  socket: state.socket
});

const ConnectedSideMenu = connect(mapStateToProps, {
  getUserList,
  getUsersInfo,
  setUserList,
  logout,
  setUserInfo,
  getUnnoticedUsers,
  setSocketUsers,
  selectUser,
  setUserLoading,
  setUserSearchTags
})(SideMenu);

export default ConnectedSideMenu;
