import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import { setAddExpenseView } from '../../../../../actions/expenseActions';
import { addNewExpense } from '../../../../../services/expenseService';
import { CategorySelector } from '../../../../Common';
import AddExpenseValidation from './AddExpenseValidation.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Swal from 'sweetalert2';
import './AddExpense.scss';

class AddExpense extends Component {
  state = {
    description: '',
    category: '',
    amount: '',
    date: new Date(),
    errors: {}
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /**
   * @description On Date change
   */
  ondDateChange = (date) => {
    this.setState({ date });
  };

  /**
   * @dec On Category change event
   * @param {*} val
   */
  onCategoryChange(val) {
    this.setState({ category: val });
  }

  /**
   * @description On Add Expense
   * @param {Object} e
   */
  onSubmit = (e) => {
    e.preventDefault();
    const { memberInfo } = this.props.user;
    const { id, phone } = memberInfo;
    const { description, category, amount, date } = this.state;
    const dateString = moment(date).format('YYYY-MM-DD');
    const addExpenseObj = { description, category, amount, date: dateString };
    let keeper_category_id;
    // Get Keeper category id
    const { categoryList } = this.props.expense;
    for (const index in categoryList) {
      if (category === categoryList[index].display_name) {
        keeper_category_id = categoryList[index].id;
      }
    }

    const { isValid, errors } = AddExpenseValidation.validateSubmit(addExpenseObj);
    if (!isValid) {
      this.setState({ errors });
    } else {
      this.setState({ errors: {} });
      const expenseObj = {
        user_id: id,
        phone,
        description,
        keeper_category_id,
        category,
        amount,
        date: dateString
      };
      this.props
        .addNewExpense(expenseObj)
        .then((res) => {
          const { status, msg } = res;
          if (!status) {
            Swal.fire({ type: 'error', title: 'Oops...', text: msg });
          } else {
            Swal.fire('Good job!', msg, 'success');
            this.props.setAddExpenseView(false);
          }
        })
        .catch((err) => {
          Swal.fire({ type: 'error', title: 'Oops...', text: err });
        });
    }
  };

  render() {
    const { description, category, amount, date, errors } = this.state;
    const { categoryList } = this.props.expense;

    return (
      <div className='add-expense'>
        <div className='expense-header'>
          <span className='title'>New Expense</span>
          <button className='close-expense' onClick={() => this.props.setAddExpenseView(false)}>
            <FontAwesomeIcon icon={['fa', 'times']} />
          </button>
        </div>
        <div className='expense-content'>
          <div className='description-section'>
            <input
              type='text'
              name='description'
              value={description}
              placeholder='Description...'
              onChange={this.onChange}
              className='expense-input'
            />
            {errors.description && <span className='val-error'>{errors.description}</span>}
          </div>
          <div className='category-section'>
            <div className='category-selector'>
              <CategorySelector
                items={categoryList}
                value={category}
                onSelect={(item) => this.onCategoryChange(item)}
                className='expense-input'
              />
            </div>
            {errors.category && <span className='val-error'>{errors.category}</span>}
          </div>
          <div className='amount-section'>
            <input
              type='text'
              name='amount'
              value={amount}
              placeholder='Amount...'
              onChange={this.onChange}
              className='expense-input'
            />
            {errors.amount && <span className='val-error'>{errors.amount}</span>}
          </div>
          <div className='date-section'>
            <DatePicker onChange={this.ondDateChange} value={date} className='customDatepicker' />
            {errors.date && <span className='val-error'>{errors.date}</span>}
          </div>
          <div className='expense-submit'>
            <button className='btn blue' type='button' onClick={this.onSubmit}>
              Add
            </button>
          </div>
        </div>
      </div>
    );
  }
}

AddExpense.propTypes = {
  setAddExpenseView: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  expense: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  expense: state.expense
});

const ConnectedAddExpense = connect(mapStateToProps, { setAddExpenseView, addNewExpense })(AddExpense);

export default ConnectedAddExpense;
