import React, { Component } from 'react';
import './DashboardContent.scss';
import RuleSections from './RuleSection/RuleSections.jsx';
import MessageSection from './MessageSection/MessageSection.jsx';
import SidePanel from './SidePanel/SidePanel.jsx';

class DashboardContent extends Component {
  render() {
    return (
      <div className='dashboardContent'>
        <RuleSections />
        <MessageSection />
        <SidePanel />
      </div>
    );
  }
}

export default DashboardContent;
