import { SET_BANK_ACCOUNT_LIST, SET_BANK_ACCOUNT_LOADER } from '../../actions/types';

const initialState = {
  accountList: [],
  loader: false
};

export default function bankAccountReducer(state = initialState, action) {
  switch (action.type) {
    case SET_BANK_ACCOUNT_LIST:
      return {
        ...state,
        accountList: action.payload,
        loader: false
      };
    case SET_BANK_ACCOUNT_LOADER:
      return {
        ...state,
        loader: action.payload
      };

    default:
      return state;
  }
}
