import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './ExpenseSection.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StatusSelector, CategorySelector, Loader } from '../../../Common';
import { getExpenseCategories, saveChanges } from '../../../../services/expenseService';
import {
  setExpenseCategoryList,
  updateLocalExpenses,
  setExpenseResMsg,
  setNumExpenseUpdates
} from '../../../../actions/expenseActions';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import M from 'materialize-css';
import AddExpense from './AddExpense';

const statusArr = ['prob', 'maybe', 'yes', 'no', 'maybe*', '-'];
const today = new Date();
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
const newDateStr = moment(today).format('YYYY-MM-DD');
const newYestStr = moment(yesterday).format('YYYY-MM-DD');

class ExpenseSection extends Component {
  state = {
    expenseList: [],
    expenseCategories: [],
    amountIndex: -1
  };

  async componentDidMount() {
    try {
      const expenseCategories = await this.props.getExpenseCategories();
      expenseCategories[0].display_name = 'none';
      // Set Category List in Redux
      this.props.setExpenseCategoryList(expenseCategories);
      this.setState({ expenseCategories });
      this.props.setExpenseResMsg({ success: '', error: '' });
    } catch (e) {
      console.log(e);
    }
  }

  componentWillReceiveProps(props) {
    const { expenseList } = props.expense;
    this.setState({
      expenseList
    });
  }

  componentDidUpdate(props) {
    const { successMsg, errorMsg } = props.expense;
    if (successMsg !== this.props.expense.successMsg || errorMsg !== this.props.expense.errorMsg) {
      setTimeout(() => {
        this.props.setExpenseResMsg({ success: '', error: '' });
      }, 1200);
    }
  }

  /**
   * @description Expense Header Date
   * @param {Array} expenseList
   * @param {Number} index
   */
  showExpenseHeader(expenseList, index) {
    const item = expenseList[index];
    const date = item.date;
    const dateStr = moment(date).format('YYYY-MM-DD');
    const dateFormt = moment(date).format('M/D');
    let displayDate;
    if (newDateStr === dateStr) {
      displayDate = 'Today';
    } else if (newYestStr === dateStr) {
      displayDate = `Yesterday`;
    } else {
      displayDate = dateFormt;
    }
    if (index === 0) {
      return (
        <div className='itemHeader'>
          <span>{displayDate}</span>
        </div>
      );
    } else {
      const prevDate = expenseList[index - 1].date;
      if (moment(prevDate).format('YYYY-MM-DD') !== moment(date).format('YYYY-MM-DD')) {
        return (
          <div className='itemHeader'>
            <span>{displayDate}</span>
          </div>
        );
      } else {
        return null;
      }
    }
  }

  /**
   * @desc Collapse Action for expense list
   * @param {*} expInd
   */
  _collapseAction(expInd) {
    let { expenseList } = this.state;
    expenseList[expInd].collapsed = !expenseList[expInd].collapsed;
    this.setState({ expenseList });
  }

  /**
   * @dec On Textbox change event
   * @param {*} e
   * @param {*} expInd
   */
  _onTxtChange(e, expInd) {
    const val = e.target.value;
    let { expenseList } = this.state;
    expenseList[expInd].clean_name = val;
    expenseList[expInd].changed = true;
    this.setState({ expenseList });
  }

  /**
   * @desc On Textbox Blur event
   * @param {*} e
   * @param {*} expInd
   */
  _onTxtBlur(e, expInd) {
    let { expenseList } = this.state;
    this.setState({ expenseList }, async () => {
      await this.props.updateLocalExpenses(expenseList);
      if (expenseList[expInd].changed) {
        await this._saveChanges(expenseList, expInd);
      }
    });
  }

  /**
   * @dec On Status change event
   * @param {*} val
   * @param {*} expInd
   */
  _onStatusChange(val, expInd) {
    let { expenseList } = this.state;
    expenseList[expInd].status = val;
    expenseList[expInd].changed = true;

    this.setState({ expenseList }, async () => {
      await this.props.updateLocalExpenses(expenseList);
      await this._saveChanges(expenseList, expInd);
    });
  }

  /**
   * @dec On Category change event
   * @param {*} val
   * @param {*} expInd
   */
  _onCategoryChange(val, expInd) {
    let { expenseList, expenseCategories } = this.state;
    expenseList[expInd].keeper_category = val;
    expenseList[expInd].changed = true;
    for (const index in expenseCategories) {
      if (val === expenseCategories[index].display_name) {
        expenseList[expInd].keeper_category_id = expenseCategories[index].id;
      }
    }
    this.setState({ expenseList }, async () => {
      await this.props.updateLocalExpenses(expenseList);
      await this._saveChanges(expenseList, expInd);
    });
  }

  /**
   * @desc On Amount Click
   * @param {*} e
   * @param {*} expInd
   */
  _onAmountClick(e, expInd) {
    this.setState({ amountIndex: expInd }, () => {
      this.expenseAmountInput.focus();
    });
  }

  /**
   * @desc On Amount textbox change event
   * @param {*} e
   * @param {*} expInd
   */
  _onAmountChange(e, expInd) {
    const val = e.target.value;
    let { expenseList } = this.state;
    expenseList[expInd].amount = val;
    expenseList[expInd].changed = true;
    this.setState({ expenseList });
  }

  /**
   * @desc On Amount Textbox Blur event
   * @param {*} e
   * @param {*} expInd
   */
  _onAmountBlur(e, expInd) {
    let { expenseList } = this.state;
    const val = parseFloat(e.target.value);
    expenseList[expInd].amount = val % 1 !== 0 ? val.toFixed(2) : val;
    this.setState({ expenseList, amountIndex: -1 }, async () => {
      await this.props.updateLocalExpenses(expenseList);
      if (expenseList[expInd].changed) {
        await this._saveChanges(expenseList, expInd);
      }
    });
  }

  /**
   * @desc save changes made in expense list
   */
  async _saveChanges(expenseList, expInd) {
    const { memberInfo } = this.props.user;
    const { user } = this.props.auth;
    const obj = {
      phone: memberInfo.phone,
      editedExpense: expenseList[expInd],
      bookkeeper: user.email
    };
    const changesSaved = await this.props.saveChanges(obj);
    if (changesSaved) {
      this.setState({ expenseList }, async () => {
        await this.props.updateLocalExpenses(expenseList);
      });
    }
  }

  render() {
    const { expenseCategories, expenseList, amountIndex } = this.state;
    const { loader, addExpenseSection, successMsg, errorMsg, numExpensesUpdated } = this.props.expense;
    const { memberInfo } = this.props.user;
    return (
      <div className='expenseSection'>
        {addExpenseSection && <AddExpense />}
        {loader && <Loader />}
        {successMsg && (
          <div className='expense-alert expense-alert-success'>
            {successMsg} ({numExpensesUpdated})
          </div>
        )}
        {errorMsg && <div className='expense-alert expense-alert-error'>{errorMsg}</div>}
        {!loader && <Loader /> && expenseList.length === 0 && (
          <div className='emptyExpense'>
            <p>Expenses are not available</p>
          </div>
        )}
        {!loader && (
          <div className='expenseList'>
            {expenseList.map((expense, expInd) => (
              <div className='expenseItem' key={`exp_i_${expInd}`}>
                {this.showExpenseHeader(expenseList, expInd)}
                <div className='itemDetails'>
                  <div className='item expStatus'>
                    <StatusSelector
                      items={statusArr}
                      value={expense.status}
                      onSelect={(item) => this._onStatusChange(item, expInd)}
                    />
                  </div>
                  <div className='item expDescription'>
                    <input
                      type='text'
                      className='txtDescription'
                      name={`desc${expInd}`}
                      onChange={(e) => this._onTxtChange(e, expInd)}
                      onBlur={(e) => this._onTxtBlur(e, expInd)}
                      value={expense.clean_name}
                    />
                  </div>
                  <div className='item expCategory'>
                    <CategorySelector
                      items={expenseCategories}
                      value={expense.keeper_category}
                      onSelect={(item) => this._onCategoryChange(item, expInd)}
                    />
                  </div>
                  <div className='item amount'>
                    {expInd !== amountIndex && <p onClick={(e) => this._onAmountClick(e, expInd)}>${expense.amount}</p>}
                    {amountIndex === expInd && (
                      <input
                        type='text'
                        className='txtbxAmount'
                        name='amount'
                        onChange={(e) => this._onAmountChange(e, expInd)}
                        onBlur={(e) => this._onAmountBlur(e, expInd)}
                        value={expense.amount}
                        ref={(input) => {
                          this.expenseAmountInput = input;
                        }}
                      />
                    )}
                  </div>
                  <div className='item pending'>
                    <span>{expense.pending ? 'P' : '-'}</span>
                  </div>
                  <div className='item control'>
                    {expense.collapsed && (
                      <FontAwesomeIcon icon={['fa', 'angle-down']} onClick={() => this._collapseAction(expInd)} />
                    )}
                    {!expense.collapsed && (
                      <FontAwesomeIcon icon={['fa', 'angle-left']} onClick={() => this._collapseAction(expInd)} />
                    )}
                    <p />
                  </div>
                </div>
                {expense.collapsed && (
                  <div className='expenseDescription'>
                    <p>
                      <b>Raw Description:</b>
                      <span>{expense.name}</span>
                      <CopyToClipboard text={expense.name} onCopy={() => M.toast({ html: 'Copied to clipboard! ' })}>
                        <button className='copy-btn'>
                          <FontAwesomeIcon icon={['fa', 'copy']} />
                        </button>
                      </CopyToClipboard>
                    </p>
                    <p>
                      <b>Plaid Category:</b>
                      <span>{expense.category}</span>
                    </p>
                    <p>
                      <b>Location:</b>
                      <span>{expense.location}</span>
                    </p>
                    <p>
                      <b>Account / Bank Name:</b>
                      <span>
                        {expense.bank_acct_name} - {expense.mask}
                      </span>
                    </p>
                    <p>
                      <b>$ Saved:</b>
                      <span>
                        {expense.tax_profile_logic === 'home'
                          ? ((((memberInfo.percent_home / 100) * memberInfo.tax_rate) / 100) * expense.amount).toFixed(
                              2
                            )
                          : expense.tax_profile_logic === 'car'
                          ? ((((memberInfo.percent_car / 100) * memberInfo.tax_rate) / 100) * expense.amount).toFixed(2)
                          : expense.tax_profile_logic === 'phone'
                          ? ((((memberInfo.percent_phone / 100) * memberInfo.tax_rate) / 100) * expense.amount).toFixed(
                              2
                            )
                          : expense.tax_profile_logic === 'meals'
                          ? (((0.5 * memberInfo.tax_rate) / 100) * expense.amount).toFixed(2)
                          : ((memberInfo.tax_rate / 100) * expense.amount).toFixed(2)}
                      </span>
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

ExpenseSection.propTypes = {
  expense: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  expense: state.expense,
  user: state.user
});

const ConnectedExpenseSection = connect(mapStateToProps, {
  getExpenseCategories,
  setExpenseCategoryList,
  updateLocalExpenses,
  saveChanges,
  setExpenseResMsg,
  setNumExpenseUpdates
})(ExpenseSection);

export default ConnectedExpenseSection;
