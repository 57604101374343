import Login from './Components/Login/Login.jsx';
import Dashboard from './Components/Dashboard/Dashboard.jsx';

/**
 * Routes Array
 * Same properties should match for all attributes
 */
const routes = [
  {
    path: '/',
    exact: true,
    component: Login,
    private: false
  },
  // Private Routes
  {
    path: '/dashboard',
    exact: true,
    component: Dashboard,
    private: true
  }
];

export default routes;
