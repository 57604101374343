import axios from 'axios';
import * as Sentry from '@sentry/browser';
import setAuthToken from '../utils/setAuthToken';
import { serverUrl } from '../global/Environment';
import jwt_decode from 'jwt-decode';
import { setCurrentUser } from '../actions/authActions';
import { setUser } from '../utils/analytics/analyticsService';
import { connectSocket } from '../actions/socketActions';

const baseUrl = serverUrl();

/**
 * @desc Social Login - Get User Token
 * @param {Object} obj Google Auth Token
 */
export const googleLogin = (obj) => (dispatch) => {
  axios
    .post(`${baseUrl}api/web-ops/auth/signin-google`, obj)
    .then((response) => response.data)
    .then((res) => {
      const { status, data } = res;
      if (status === 'ok') {
        const { token } = data;
        // Set token to ls
        localStorage.setItem('KeeperToken', token);
        // Set token to Auth header
        setAuthToken(token);
        // Decode token to get user data
        const decoded = jwt_decode(token);
        // Set current user
        dispatch(setCurrentUser(decoded));
        dispatch(connectSocket());
        setUser(decoded);
        Sentry.setUser({ id: decoded.id, email: decoded.email });
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
