import React from 'react';
import { useFormUploadFiles } from '../../../../services/messageService';
import { DataGrid } from '@mui/x-data-grid';
import { LinearProgress } from '@mui/material';

const FormUploadFileSection = () => {
  const { files, error, loading } = useFormUploadFiles();

  if (error) {
    return <div>{error.message}</div>;
  }

  const columns = [
    {
      field: 'createdAt',
      headerName: 'Upload Date',
      type: 'dateTime',
      valueGetter: (_value, file) => file.createdAt && new Date(file.createdAt)
    },
    {
      field: 'file_name',
      headerName: 'Filename'
    },
    {
      field: 'signedUrl',
      headerName: 'Link',
      renderCell: (params) => {
        const file = params.row;

        return (
          <a href={file.signedUrl} target='_blank' rel='noopener noreferrer'>
            Link
          </a>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status'
    },
    {
      field: 'page',
      headerName: 'Page'
    }
  ];

  return (
    <DataGrid
      rows={files}
      slots={{
        loadingOverlay: LinearProgress
      }}
      columns={columns}
      loading={loading}
      autosizeOnMount
      autoPageSize
    />
  );
};

export default FormUploadFileSection;
