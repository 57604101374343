import React, { Component } from 'react';
import className from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Modal.module.scss';

class Modal extends Component {
  state = {
    modalOpen: this.props.open
  };

  close = ({ keyCode }) => {
    if (keyCode === 27) {
      this.props.handleClose();
    }
  };

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener('keydown', this.close);
  }

  componentWillReceiveProps({ open }) {
    this.setState({ modalOpen: open });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.close);
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    } else {
      this.props.handleClose();
    }
  };

  render() {
    const { modalOpen } = this.state;
    const { children, size } = this.props;

    const modalClass = className({
      [styles.customModal]: true,
      [styles.show]: modalOpen
    });

    const modalcontentClass = className({
      [styles.modalDialog]: true,
      [styles.big]: size !== undefined && size === 'big',
      [styles.medium]: size !== undefined && size === 'medium',
      [styles.small]: size !== undefined && size === 'small'
    });

    return (
      <div className={modalClass}>
        <div className={modalcontentClass}>
          <div className={styles.modalContent} ref={(node) => (this.node = node)}>
            <button className={styles.modalClose} onClick={this.props.handleClose}>
              <FontAwesomeIcon icon={['fa', 'times']} />
            </button>
            <div className={styles.modalBody}>{children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
