/**
 * @desc Image Urls
 */
import * as googleLoginImage from '../assets/img/google_logo.png';

/**
 * @desc Global Parameters
 */
export const PROJECT_NAME = 'Keepers Tax'; // Project Name
export const GOOGLE_SIGN_IMAGE = googleLoginImage; // Google Sign In Image
export const GOOGLE_LOGIN_CLIENT_ID = process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID; // Google + API client ID
export const REACT_APP_ENV = process.env.REACT_APP_ENV;
export const TWILLIO_PHONES_PLUS_STREAM = new Set([
  '14156448982',
  '14156105454',
  '16507619720',
  '16507619552',
  '14155344313',
  '14155344283',
  '14158549633',
  '14158434992',
  '14157273696',
  '14157273303',
  '14158731769',
  '14158732314',
  '14158740723',
  '14153048327',
  '14155926286',
  '14153048703',
  '0'
]);

/**
 * @desc Get server URL based on host name
 */
export function serverUrl() {
  if (REACT_APP_ENV === 'development') {
    return 'http://localhost:5001/';
  } else if (REACT_APP_ENV === 'staging') {
    return 'https://keeper-backend-staging.keepertax.com/';
  } else if (REACT_APP_ENV === 'staging-2') {
    return 'https://keeper-backend-staging-2.keepertax.com/';
  } else if (REACT_APP_ENV === 'production') {
    return 'https://keeper-backend.keepertax.com/';
  }
}

/**
 * @desc Get server URL based on host name
 */
export function socketUrl() {
  if (REACT_APP_ENV === 'development') {
    return 'ws://localhost:5001/';
  } else if (REACT_APP_ENV === 'staging') {
    return 'wss://keeper-backend-staging.keepertax.com/';
  } else if (REACT_APP_ENV === 'staging-2') {
    return 'wss://keeper-backend-staging-2.keepertax.com/';
  } else if (REACT_APP_ENV === 'production') {
    return 'wss://keeper-backend.keepertax.com/';
  }
}
