import React from 'react';
import classNames from 'classnames';

const Lines = ({ className, body, onClick }) => {
  return (
    <div className={classNames('lines', className)} onClick={onClick}>
      {body}
    </div>
  );
};

export default Lines;
