import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import Select from 'react-select';
import { fetchTags } from '../../../services/tagService';
import { filterUsersBySearchTags } from '../../../services/userService';
import { setHasUserTagFilterModal } from '../../../actions/userActions';
import _ from 'lodash';
import './UserTagFilter.scss';

export const UserTagFilter = ({
  tags,
  userSearchTags,
  fetchTags,
  filterUsersBySearchTags,
  setHasUserTagFilterModal
}) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [allPossibleTags, setAllPossibleTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);

  const tagsEmpty = _.isEmpty(tags);

  useEffect(() => {
    if (tagsEmpty) {
      fetchTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsEmpty]);

  useEffect(() => {
    if (tags) {
      setAllPossibleTags(
        tags.map((tag) => ({
          ...tag,
          value: tag.id,
          label: tag.name
        }))
      );
    }
  }, [tags]);

  useEffect(() => {
    if (userSearchTags === undefined) {
      setHasChanged(isEnabled);
      return;
    }
    setHasChanged(
      !isEnabled ||
        !_.isEmpty(
          _.xor(
            userSearchTags,
            selectedTags.map((tag) => tag.id)
          )
        )
    );
  }, [userSearchTags, selectedTags, isEnabled]);

  function onToggleFilter(toEnabled) {
    setIsEnabled(toEnabled);
  }

  function onSelectChange(selectedTags) {
    setSelectedTags(selectedTags);
  }

  function onSubmit() {
    const searchTags = isEnabled ? selectedTags.map(({ value }) => value) : undefined;
    filterUsersBySearchTags(searchTags);
    setHasUserTagFilterModal(false);
  }

  return (
    <>
      <h5 className='user-filters-header'>Filter Users</h5>
      <div className='user-tags-filter'>
        <p>Filter users by tags</p>
        <Switch onChange={onToggleFilter} checked={isEnabled} />
        {isEnabled && (
          <Select
            className='user-tags-filter-select'
            isMulti={true}
            options={allPossibleTags}
            value={selectedTags}
            backspaceRemovesValue={false}
            onChange={onSelectChange}
          />
        )}
      </div>
      <div className='user-filters-submit'>
        <button className='btn blue' type='submit' onClick={onSubmit} disabled={!hasChanged}>
          Save Changes
        </button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  tags: state.tag.tags,
  userSearchTags: state.user.userSearchTags
});

const ConnectedUserTagFilter = connect(mapStateToProps, {
  fetchTags,
  filterUsersBySearchTags,
  setHasUserTagFilterModal
})(UserTagFilter);

export default ConnectedUserTagFilter;
