import React, { useState } from 'react';
import './SidePanel.scss';
import { Tab, Tabs } from '@mui/material';
import ExpenseSection from '../ExpenseSection/index.js';
import ChatFileSection from '../FileSection/ChatFileSection.jsx';
import FormUploadFileSection from '../FileSection/FormUploadFileSection.jsx';

const SECTIONS = {
  Expenses: {
    label: 'Expenses',
    Component: ExpenseSection
  },
  'Chat Files': {
    label: 'Chat Files',
    Component: ChatFileSection
  },
  'Form Upload Files': {
    label: 'Form Upload Files',
    Component: FormUploadFileSection
  }
};

/**
 * @type {React.FC}
 */
const SidePanel = () => {
  const [selectedSection, setSelectedSection] = useState('Expenses');

  const handleChange = (_event, newValue) => {
    setSelectedSection(newValue);
  };

  const Component = SECTIONS[selectedSection].Component;

  return (
    <div className='side-panel'>
      <Tabs value={selectedSection} onChange={handleChange} indicatorColor='secondary'>
        {Object.keys(SECTIONS).map((label) => (
          <Tab key={label} label={label} value={label} />
        ))}
      </Tabs>
      {!!Component && <Component />}
    </div>
  );
};

export default SidePanel;
