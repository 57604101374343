import { SET_MACRO_MODAL_STATUS } from './types';

/**
 * @desc Set macro modal status like open/close
 * @param {Boolean} payload
 */
export const setMacroModalStatus = (payload) => {
  return {
    type: SET_MACRO_MODAL_STATUS,
    payload
  };
};
