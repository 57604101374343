import axios from 'axios';
import {
  setChatScroll,
  setDataMistakeTypes,
  setMessageList,
  setMessageLoading,
  setImportToQDeckModalStatus
} from '../actions/chatActions';
import { serverUrl } from '../global/Environment';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const baseUrl = serverUrl();

/**
 * @desc Get Users Messages
 * @param {Object} obj
 */
export const getUsersMessages = (obj) => () => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}api/web-ops/message/getMessageList`, obj)
      .then((response) => response.data)
      .then((res) => {
        const { status, msg } = res;
        if (status === 'ok') {
          const { data } = res;
          resolve(data);
        } else {
          reject(msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @desc Send Message To User
 * @param {Object} obj
 */
export const sendMessage = (obj) => () => {
  if (obj.platform === 'stream') {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}api/web-ops/message/send-stream-message`, obj)
        .then((response) => response.data)
        .then((res) => {
          const { status, msg } = res;
          if (status === 'ok') {
            resolve(res);
          } else {
            reject(msg);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}api/web-ops/message/sendMessage`, obj)
        .then((response) => response.data)
        .then((res) => {
          const { status, msg } = res;
          if (status === 'ok') {
            resolve(res);
          } else {
            reject(msg);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

/**
 * @desc Send Internal Message to DB
 * @param {Object} obj
 */
export const internalMessage = (obj) => () => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}api/web-ops/message/internal-message`, obj)
      .then((response) => response.data)
      .then((res) => {
        const { status, msg } = res;
        if (status === 'ok') {
          resolve(res);
        } else {
          reject(msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @description Get Chat Message and set it in store
 * @param {String} phone
 */
export const getChatMessageList = (userId, callback) => async (dispatch) => {
  dispatch(setMessageLoading(true));
  const messageObj = { userId };
  try {
    const messageList = await dispatch(getUsersMessages(messageObj));
    let mappedMessages = messageList.map((m) => {
      return { ...m, delivered: true };
    });
    /**
     * Show only 200 messages and sort messages based on date.
     */
    mappedMessages.reverse();
    dispatch(setMessageList(mappedMessages));
    dispatch(setChatScroll({ scroll: true, style: 'auto' }));
  } catch (e) {
    console.log(e);
    dispatch(setMessageList([]));
  }
  dispatch(setMessageLoading(false));
  callback();
};

export const getDataMistakeTypes = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/web-ops/message/data-mistake-types`).then((response) => response.data);
  const { status, data: dataMistakeTypes, msg } = res;
  if (status === 'ok') {
    dispatch(setDataMistakeTypes(dataMistakeTypes));
  } else {
    throw Error(msg);
  }
};

export const recordDataMistake = (body) => async () => {
  const res = await axios
    .post(`${baseUrl}api/web-ops/message/record-data-mistake`, body)
    .then((response) => response.data);
  const { status, msg } = res;
  if (status !== 'ok') {
    throw Error(msg);
  }
};

export const getSignedUrl = ({ userId, key }) => async () => {
  const res = await axios.get(`${baseUrl}api/web-ops/message/${userId}/files/${key}`);

  if (res?.data?.status !== 'ok') {
    throw new Error(res?.data?.msg || 'Something went wrong');
  }

  const data = _.get(res, ['data', 'data']);

  return data;
};

/**
 * @param {number} userId
 * @returns {Promise<BaseFile[]>}
 */
export const getChatFiles = (userId) => async () => {
  const response = await axios.get(`${baseUrl}api/web-ops/files/chat/${userId}`);

  if (response?.data?.status !== 'ok') {
    throw new Error(response?.data?.msg || 'Something went wrong');
  }

  const data = _.get(response, ['data', 'data']);

  return data;
};

/**
 * @param {number} userId
 * @returns {Promise<FormUploadFile[]>}
 */
export const getFormUploadFiles = (userId) => async () => {
  const response = await axios.get(`${baseUrl}api/web-ops/files/form-upload/${userId}`);

  if (response?.data?.status !== 'ok') {
    throw new Error(response?.data?.msg || 'Something went wrong');
  }

  const data = _.get(response, ['data', 'data']);

  return data;
};

/**
 * @template T
 * @param {(userId: string) => Promise<T[]>} getFiles
 * @returns {() => {files: T[], error: Error, loading: boolean}}
 */
export const createUseFiles = (getFiles) => () => {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const userId = useSelector((state) => state.user.memberInfo.id);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (_.isNil(userId)) {
        setError(new Error('No user selected'));
        return;
      }

      try {
        const result = await dispatch(getFiles(userId));

        setFiles(result);
        setError(null);
      } catch (e) {
        setError(new Error('Failed to fetch files.'));
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch, userId]);

  return { files, error, loading };
};

/**
 * @typedef BaseFile
 * @property {number} id
 * @property {string} file_name
 * @property {string} key
 * @property {string} signedUrl
 * @property {string} thumbnailSignedUrl
 * @property {'file/image' | 'file/pdf'} type
 * @property {string} createdAt
 * @property {string} updatedAt
 */

/**
 * @returns {{files: BaseFile[], error: Error}}
 */
export const useChatFiles = createUseFiles(getChatFiles);

/**
 * @typedef {BaseFile & { page, status }} FormUploadFile
 */

/**
 * @returns {{files: FormUploadFile[], error: Error}}
 */
export const useFormUploadFiles = createUseFiles(getFormUploadFiles);

export const importMessageToQDeck = ({ userId, authorEmail, authorName, authorType, text, timestamp }) => async (
  dispatch
) => {
  try {
    const res = await axios.post(`${baseUrl}api/web-ops/message/import-message-to-qdeck`, {
      userId,
      authorEmail,
      authorName,
      authorType,
      text,
      timestamp
    });
    const { msg } = res.data;
    dispatch(setImportToQDeckModalStatus({ open: true, message: msg }));
  } catch (e) {
    dispatch(setImportToQDeckModalStatus({ open: true, message: e.message }));
  }
};
