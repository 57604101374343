import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './RuleSection.scss';
import Rules from './Rules/Rules.jsx';
import { Modal } from '../../../Common';
import { setRuleModalStatus } from '../../../../actions/ruleActions';

class RuleSection extends Component {
  render() {
    const { hasRuleModal } = this.props.rule;
    return (
      <div className='RuleSection'>
        <Modal open={hasRuleModal} size='big' handleClose={() => this.props.setRuleModalStatus(false)}>
          {hasRuleModal && <Rules />}
        </Modal>
      </div>
    );
  }
}

RuleSection.propTypes = {
  user: PropTypes.object.isRequired,
  rule: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  rule: state.rule
});

const ConnectedRuleSection = connect(mapStateToProps, { setRuleModalStatus })(RuleSection);

export default ConnectedRuleSection;
