/**
 * @desc set userid and email for segment analytics
 */
export const setUser = (user) => {
  try {
    if (user && user.id) {
      const { id, firstName, lastName, email } = user;
      window.analytics.identify(`bk-${id}`, {
        bookkeeperFirstName: firstName,
        bookkeeperLastName: lastName,
        bookkeeperEmail: email
      });
    } else {
      clearUser();
    }
  } catch (e) {
    console.log(e);
  }
};

/**
 * @desc clear user information for segment analytics
 */
export const clearUser = () => {
  try {
    console.log('clearUser');
    window.analytics.reset();
  } catch (e) {
    console.log(e);
  }
};

/**
 * @desc track activity in segment analytics
 */
export const trackActivity = (name, params) => {
  try {
    const newParams = {
      ...params,
      environment: process.env.REACT_APP_ENV
    };

    window.analytics.track(name, newParams);
  } catch (e) {
    console.log(e);
  }
};
