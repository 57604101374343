import axios from 'axios';
import { serverUrl } from '../global/Environment';
import {
  setExpenseList,
  setExpenseLoading,
  setExpenseResMsg,
  setNumExpenseUpdates,
  updateLocalExpenses
} from '../actions/expenseActions';

const baseUrl = serverUrl();

/**
 * @desc Get Users Expense List
 * @param {Object} obj
 */
export const getExpenseList = (obj) => () => {
  return new Promise((resolve, reject) => {
    const { page, perPage } = obj;
    axios
      .post(`${baseUrl}api/web-ops/expense/getExpenses?page=${page}&perPage=${perPage}`, obj)
      .then((response) => response.data)
      .then((res) => {
        const { status, msg } = res;
        if (status === 'ok') {
          const { totalRecords, totalPages, currentPage, data } = res;
          resolve({ totalRecords, totalPages, currentPage, data });
        } else {
          reject(msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchUserExpenseList = (phone, callback) => async (dispatch) => {
  dispatch(setExpenseLoading(true));
  const expenseObj = { phone };
  try {
    const { data } = await getExpenseList(expenseObj)(dispatch);
    const mappedExpenses = await filterExpense(data);
    dispatch(setExpenseList(mappedExpenses));
    dispatch(updateLocalExpenses(mappedExpenses));
  } catch (e) {
    console.log(e);
    dispatch(setExpenseLoading(false));
    dispatch(setExpenseList([]));
    dispatch(updateLocalExpenses([]));
  }

  if (typeof callback === 'function') {
    callback();
  }
};

/**
 * @desc Get All Expense Categories
 */
export const getExpenseCategories = () => () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}api/web-ops/expense/loadCatMap`)
      .then((response) => response.data)
      .then((res) => {
        const { status, msg } = res;
        if (status === 'ok') {
          const { data } = res;
          resolve(data);
        } else {
          reject(msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const filterExpense = (expenseList) => {
  const expenses = expenseList.map((data) => {
    let status = data.status;
    if (data.status === 'yes' && data.user_notified === 0) {
      status = 'prob';
    } else if (data.status === 'maybe' && data.user_notified === 1) {
      status = 'maybe*';
    }
    const retObj = {
      ...data,
      amount: data.amount % 1 !== 0 ? data.amount.toFixed(2) : data.amount,
      collapsed: false,
      changed: !!(status === 'prob' || status === 'maybe'),
      status
    };
    return retObj;
  });
  return expenses;
};

/**
 * @desc Add New Expense
 * @param {Object} obj
 */
export const addNewExpense = (obj) => () => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}api/web-ops/expense/add-new-expense`, obj)
      .then((response) => response.data)
      .then((res) => {
        const { status, msg } = res;
        if (status === 'ok') {
          resolve(res);
        } else {
          reject(msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSavings = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}api/web-ops/expense/savings/${userId}`)
      .then((response) => response.data)
      .then((res) => {
        const { status, msg } = res;
        if (status === 'ok') {
          resolve(res);
        } else {
          reject(msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const saveChanges = (obj) => async (dispatch) => {
  try {
    const res = await axios.post(`${baseUrl}api/web-ops/expense/save-expense-change`, obj);
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setExpenseResMsg({ success: msg }));
      dispatch(setNumExpenseUpdates());
      return true;
    } else {
      dispatch(setExpenseResMsg({ error: msg || 'Something went wrong, please try again' }));
      return false;
    }
  } catch (e) {
    dispatch(setExpenseResMsg({ error: e.msg || 'Something went wrong, please try again' }));
    return e;
  }
};
