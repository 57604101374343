import React from 'react';
import Default from './Default/Default.jsx';
import Bars from './Bars/Bars.jsx';

const Loader = ({ type, name }) => {
  let loaderName;

  if (name !== undefined && name === 'bars') {
    loaderName = 'bars';
  } else {
    loaderName = 'default';
  }

  if (loaderName === 'default') {
    return <Default type={type} />;
  } else if (loaderName === 'bars') {
    return <Bars />;
  }
};

export default Loader;
