import {
  GET_USERS_EXPENSE_LIST,
  EXPENSE_LOADING,
  GET_EXPENSE_CATEGORY_LIST,
  UPDATE_LOCAL_EXPENSES,
  SET_ADD_EXPENSE_SECTION_VIEW,
  UPDATE_EXPENSE_RES_MSG,
  SET_NUM_EXPENSES_UPDATED,
  RESET_NUM_EXPENSES_UPDATED,
  SET_SAVINGS
} from './types';

/**
 * @desc Set Users expense list dispatch service
 * @param {*} expenses
 */
export const setExpenseList = (expenses) => {
  return {
    type: GET_USERS_EXPENSE_LIST,
    payload: expenses
  };
};

/**
 * @desc Set Expense loading
 */
export const setExpenseLoading = (payload) => {
  return {
    type: EXPENSE_LOADING,
    payload
  };
};

/**
 * @desc Set Users expense list dispatch service
 * @param {*} categories
 */
export const setExpenseCategoryList = (categories) => {
  return {
    type: GET_EXPENSE_CATEGORY_LIST,
    payload: categories
  };
};

/**
 * @desc Update Local Expense List
 * @param {*} expense
 */
export const updateLocalExpenses = (expense) => {
  return {
    type: UPDATE_LOCAL_EXPENSES,
    payload: expense
  };
};

/**
 * @desc Set Add Expense View
 * @param {Boolean} payload
 */
export const setAddExpenseView = (payload) => {
  return {
    type: SET_ADD_EXPENSE_SECTION_VIEW,
    payload
  };
};

/**
 * @desc Update expense res messages
 */
export const setExpenseResMsg = (payload) => {
  const { success, error } = payload;
  const resObj = {
    success: success || '',
    error: error || ''
  };
  return {
    type: UPDATE_EXPENSE_RES_MSG,
    payload: resObj
  };
};

/**
 * @desc keep track of number of expense updates made by the bookkeeper
 */
export const setNumExpenseUpdates = () => {
  return {
    type: SET_NUM_EXPENSES_UPDATED
  };
};

export const resetNumExpenseUpdates = () => {
  return {
    type: RESET_NUM_EXPENSES_UPDATED
  };
};

export const setSavings = (payload) => {
  return {
    type: SET_SAVINGS,
    payload
  };
};

/**
 * @desc Filter Expenses
 */
export const _filterExpense = (expenseList) => {
  const expenses = expenseList.map((data, index) => {
    let retObj = {};
    retObj['id'] = index;
    retObj['account_id'] = data.account_id;
    retObj['amount'] = data.amount % 1 !== 0 ? data.amount.toFixed(2) : data.amount;
    retObj['category'] = data.category;
    retObj['category_id'] = data.category_id;
    retObj['date'] = data.date;
    retObj['location'] = data.location;
    retObj['name'] = data.name;
    retObj['pending'] = data.pending;
    retObj['pending_transaction_id'] = data.pending_transaction_id;
    retObj['transaction_id'] = data.transaction_id;
    retObj['acct_name'] = data.acct_name;
    retObj['bank_acct_name'] = data.bank_acct_name;
    retObj['sms_sent'] = data.sms_sent;
    retObj['write_off'] = data.write_off;
    retObj['likely_dup'] = data.likely_dup;
    retObj['clean_name'] = data.clean_name;
    retObj['keeper_pending_id'] = data.keeper_pending_id;
    retObj['status'] = data.status;
    retObj['keeper_category'] = data.keeper_category;
    retObj['keeper_category_id'] = data.keeper_category_id;
    retObj['collapsed'] = false;
    retObj['user_notified'] = data.user_notified;
    retObj['changed'] = !!(data.user_notified === 0 && ['maybe', 'yes'].includes(data.status));
    return retObj;
  });
  return expenses;
};
