import {
  GET_USERS_EXPENSE_LIST,
  EXPENSE_LOADING,
  GET_EXPENSE_CATEGORY_LIST,
  UPDATE_LOCAL_EXPENSES,
  CLEAR_EXPENSE_REDUCER,
  SET_ADD_EXPENSE_SECTION_VIEW,
  UPDATE_EXPENSE_RES_MSG,
  SET_NUM_EXPENSES_UPDATED,
  RESET_NUM_EXPENSES_UPDATED,
  SET_SAVINGS
} from '../../actions/types';

const initialState = {
  expenseList: [],
  loader: false,
  categoryList: [],
  localExpenseList: [],
  addExpenseSection: false,
  successMsg: '',
  errorMsg: '',
  numExpensesUpdated: 0,
  savings: {}
};

export default function expenseReducer(state = initialState, action) {
  switch (action.type) {
    case EXPENSE_LOADING:
      return {
        ...state,
        loader: action.payload
      };
    case GET_USERS_EXPENSE_LIST:
      return {
        ...state,
        expenseList: action.payload,
        loader: false
      };
    case GET_EXPENSE_CATEGORY_LIST:
      return {
        ...state,
        categoryList: action.payload
      };
    case UPDATE_LOCAL_EXPENSES:
      return {
        ...state,
        localExpenseList: action.payload
      };
    case CLEAR_EXPENSE_REDUCER:
      return {
        ...state,
        expenseList: [],
        categoryList: [],
        localExpenseList: []
      };
    case SET_ADD_EXPENSE_SECTION_VIEW:
      return {
        ...state,
        addExpenseSection: action.payload
      };
    case UPDATE_EXPENSE_RES_MSG:
      return {
        ...state,
        successMsg: action.payload.success,
        errorMsg: action.payload.error
      };
    case SET_NUM_EXPENSES_UPDATED:
      return {
        ...state,
        numExpensesUpdated: ++state.numExpensesUpdated
      };
    case RESET_NUM_EXPENSES_UPDATED:
      return {
        ...state,
        numExpensesUpdated: 0
      };
    case SET_SAVINGS:
      return {
        ...state,
        savings: action.payload
      };
    default:
      return state;
  }
}
