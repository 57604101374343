import { SET_TAGS, SET_TAG_LOADER, SET_TAG_MODAL_STATUS, UPDATE_TAG_RES_MSG } from '../../actions/types';

const initialState = {
  tags: [],
  hasTagModal: false,
  successMessage: undefined,
  errorMessage: undefined,
  tagLoader: false
};

export default function tagReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TAGS:
      return {
        ...state,
        tags: action.payload
      };
    case SET_TAG_MODAL_STATUS:
      return {
        ...state,
        hasTagModal: action.payload
      };
    case UPDATE_TAG_RES_MSG:
      return {
        ...state,
        successMsg: action.payload.success,
        errorMsg: action.payload.error
      };
    case SET_TAG_LOADER:
      return {
        ...state,
        tagLoader: action.payload
      };
    default:
      return state;
  }
}
