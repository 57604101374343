/**
 * @desc Reducers Action List
 */
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// User
export const GET_USERS_LIST = 'GET_USERS_LIST';
export const GET_USERS_DETAILS = 'GET_USERS_DETAILS';
export const CLEAR_USER_REDUCER = 'CLEAR_USER_REDUCER';
export const SET_FILTER_STATUS = 'SET_FILTER_STATUS';
export const SET_NO_MORE_BLUE_DOTS = 'SET_NO_MORE_BLUE_DOTS';
export const SET_MAX_REACHED = 'SET_MAX_REACHED';
export const SET_NUM_BLUE_DOTS = 'SET_NUM_BLUE_DOTS';
export const SET_OUTSTANDING_BLUE = 'SET_OUTSTANDING_BLUE';
export const SET_USER_TAG_FILTER_MODAL_STATUS = 'SET_USER_TAG_FILTER_MODAL_STATUS';
export const SET_USER_SEARCH_TAGS = 'SET_USER_SEARCH_TAGS';
export const SET_USER_LOADING = 'SET_USER_LOADING';

// Messages
export const MESSAGES_LOADING = 'MESSAGES_LOADING';
export const GET_USERS_MESSAGE_LIST = 'GET_USERS_MESSAGE_LIST';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const CLEAR_CHAT_REDUCER = 'CLEAR_CHAT_REDUCER';
export const CHAT_SCROLL = 'CHAT_SCROLL';
export const ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE';
export const UPDATE_EXPENSE_MAPPING = 'UPDATE_EXPENSE_MAPPING';
export const CLEAR_EXPENSE_MAPPING = 'CLEAR_EXPENSE_MAPPING';
export const UPDATE_PROB_LENGTH = 'UPDATE_PROB_LENGTH';
export const CLEAR_PROB_LENGTH = 'CLEAR_PROB_LENGTH';
export const SET_DATA_MISTAKES_MODAL_STATUS = 'SET_DATA_MISTAKES_MODAL_STATUS';
export const SET_DATA_MISTAKE_TYPES = 'SET_DATA_MISTAKE_TYPES';
export const IMPORT_TO_QDECK_MODAL_STATUS = 'IMPORT_TO_QDECK_MODAL_STATUS';

// Expenses
export const GET_USERS_EXPENSE_LIST = 'GET_USERS_EXPENSE_LIST';
export const EXPENSE_LOADING = 'EXPENSE_LOADING';
export const GET_EXPENSE_CATEGORY_LIST = 'GET_EXPENSE_CATEGORY_LIST';
export const UPDATE_LOCAL_EXPENSES = 'UPDATE_LOCAL_EXPENSES';
export const CLEAR_EXPENSE_REDUCER = 'CLEAR_EXPENSE_REDUCER';
export const SET_ADD_EXPENSE_SECTION_VIEW = 'SET_ADD_EXPENSE_SECTION_VIEW';
export const UPDATE_EXPENSE_RES_MSG = 'UPDATE_EXPENSE_RES_MSG';
export const SET_NUM_EXPENSES_UPDATED = 'SET_NUM_EXPENSES_UPDATED';
export const RESET_NUM_EXPENSES_UPDATED = 'RESET_NUM_EXPENSES_UPDATED';
export const SET_SAVINGS = 'SET_SAVINGS';

// Socket
export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT';
export const SOCKET_SET_USERS = 'SOCKET_SET_USERS';
export const SOCKET_ADD_USER = 'SOCKET_ADD_USER';
export const SOCKET_REMOVE_USER = 'SOCKET_REMOVE_USER';

// Bank Accuont
export const SET_BANK_ACCOUNT_LIST = 'SET_BANK_ACCOUNT_LIST';
export const SET_BANK_ACCOUNT_LOADER = 'SET_BANK_ACCOUNT_LOADER';

// Rule
export const GET_USERS_RULE_LIST = 'GET_USERS_RULE_LIST';
export const SET_RULE_MODAL_STATUS = 'SET_RULE_MODAL_STATUS';
export const SET_RULE_LOADER = 'SET_RULE_LOADER';
export const UPDATE_RULE_RES_MSG = 'UPDATE_RULE_RES_MSG';

// Macro
export const SET_MACRO_MODAL_STATUS = 'SET_MACRO_MODAL_STATUS';

// Tag
export const SET_TAGS = 'SET_TAGS';
export const SET_TAG_MODAL_STATUS = 'SET_TAG_MODAL_STATUS';
export const UPDATE_TAG_RES_MSG = 'UPDATE_TAG_RES_MSG';
export const SET_TAG_LOADER = 'UPDATE_TAG_RES_MSG';
