import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createImpersonationLink } from '../../../../services/userService';
import './ImpersonationSection.scss';

class ImpersonationSection extends Component {
  async _impersonateUser() {
    await this.props.createImpersonationLink({ impersonateeId: _.get(this, ['props', 'user', 'memberInfo', 'id']) });
  }

  render() {
    if (_.isEmpty(_.get(this, ['props', 'user', 'memberInfo']))) {
      return null;
    }

    return (
      <div className='impersonationSection'>
        <button type='button' className='btn impersonationButton' onClick={() => this._impersonateUser()}>
          Open Portal
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user
});

const mapDispatchToProps = {
  createImpersonationLink
};

const ConnectedImpersonationSection = connect(mapStateToProps, mapDispatchToProps)(ImpersonationSection);

export default ConnectedImpersonationSection;
