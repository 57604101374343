import {
  GET_USERS_MESSAGE_LIST,
  MESSAGES_LOADING,
  CLEAR_CHAT_REDUCER,
  CHAT_SCROLL,
  ADD_NEW_MESSAGE,
  UPDATE_EXPENSE_MAPPING,
  CLEAR_EXPENSE_MAPPING,
  UPDATE_PROB_LENGTH,
  CLEAR_PROB_LENGTH,
  UPDATE_MESSAGE,
  SET_DATA_MISTAKES_MODAL_STATUS,
  SET_DATA_MISTAKE_TYPES,
  IMPORT_TO_QDECK_MODAL_STATUS
} from '../../actions/types';

const initialState = {
  messageList: [],
  loader: false,
  chatScroll: {
    scroll: false,
    style: 'auto'
  },
  expenseMapping: [],
  probLength: 0,
  dataMistakeTypes: [],
  hasDataMistakesModal: false,
  dataMistakesMessage: undefined,
  importToQDeckModalOpen: false,
  importToQDeckModalMessage: null
};
export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case MESSAGES_LOADING:
      return {
        ...state,
        loader: action.payload
      };
    case GET_USERS_MESSAGE_LIST:
      return {
        ...state,
        messageList: action.payload,
        loader: false
      };
    case UPDATE_MESSAGE:
      return {
        ...state,
        messageList: state.messageList.map((message) =>
          action.payload.id === message.id ? { ...message, ...action.payload.message } : message
        )
      };
    case CLEAR_CHAT_REDUCER:
      return {
        ...state,
        messageList: []
      };
    case CHAT_SCROLL:
      return {
        ...state,
        chatScroll: action.payload
      };
    case ADD_NEW_MESSAGE:
      return {
        ...state,
        messageList: action.payload
      };
    case UPDATE_EXPENSE_MAPPING:
      return {
        ...state,
        expenseMapping: action.payload
      };
    case CLEAR_EXPENSE_MAPPING:
      return {
        ...state,
        expenseMapping: []
      };
    case UPDATE_PROB_LENGTH:
      return {
        ...state,
        probLength: action.payload
      };
    case CLEAR_PROB_LENGTH:
      return {
        ...state,
        probLength: 0
      };

    case SET_DATA_MISTAKE_TYPES:
      return {
        ...state,
        dataMistakeTypes: action.payload
      };

    case SET_DATA_MISTAKES_MODAL_STATUS:
      return {
        ...state,
        hasDataMistakesModal: action.payload.open,
        dataMistakesMessage: action.payload.message
      };
    case IMPORT_TO_QDECK_MODAL_STATUS:
      return {
        ...state,
        importToQDeckModalOpen: action.payload.open,
        importToQDeckModalMessage: action.payload.message
      };
    default:
      return state;
  }
}
