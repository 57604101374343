import { combineReducers } from 'redux';
import authReducer from './authReducer';
import bankAccountReducer from './bankAccountReducer';
import chatReducer from './chatReducer';
import errorReducer from './errorReducer';
import expenseReducer from './expenseReducer';
import socketReducer from './socketReducer';
import userReducer from './userReducer';
import ruleReducer from './ruleReducer';
import macroReducer from './macroReducer';
import tagReducer from './tagReducer';

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  user: userReducer,
  chat: chatReducer,
  expense: expenseReducer,
  socket: socketReducer,
  bankAccount: bankAccountReducer,
  rule: ruleReducer,
  macro: macroReducer,
  tag: tagReducer
});
