import axios from 'axios';
import { serverUrl } from '../global/Environment';

const baseUrl = serverUrl();

/**
 * @desc Get macros List
 * @param {Object} obj
 */
export const getMacroList = () => axios.get(`${baseUrl}api/web-ops/macro/macros`);
