import * as Sentry from '@sentry/browser';
import setAuthToken from '../utils/setAuthToken';
import { disconnectSocket } from './socketActions';
import { SET_CURRENT_USER, CLEAR_USER_REDUCER, CLEAR_CHAT_REDUCER, CLEAR_EXPENSE_REDUCER } from './types';

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// Log user out
export const logout = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem('KeeperToken');
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  dispatch(clearUserReducer());
  dispatch(clearChatReducer());
  dispatch(clearExpenseReducer());
  dispatch(disconnectSocket());
  Sentry.setUser(null);
};

/**
 * @desc Clear User State
 */
export const clearUserReducer = () => {
  return {
    type: CLEAR_USER_REDUCER
  };
};

/**
 * @desc Clear Chat State
 */
export const clearChatReducer = () => {
  return {
    type: CLEAR_CHAT_REDUCER
  };
};

/**
 * @desc Clear Expense State
 */
export const clearExpenseReducer = () => {
  return {
    type: CLEAR_EXPENSE_REDUCER
  };
};
