import { SET_TAGS, SET_TAG_MODAL_STATUS, UPDATE_TAG_RES_MSG, SET_TAG_LOADER } from './types';

export const setTags = (payload) => {
  return {
    type: SET_TAGS,
    payload
  };
};

export const setHasTagModal = (payload) => {
  return {
    type: SET_TAG_MODAL_STATUS,
    payload
  };
};

export const setTagResMessage = (payload) => {
  return {
    type: UPDATE_TAG_RES_MSG,
    payload
  };
};

export const setTagLoader = (payload) => {
  return {
    type: SET_TAG_LOADER,
    payload
  };
};
