import React from 'react';
import barsLoader from '../../../../assets/img/bars.svg';
import styles from './Bars.module.scss';

const Default = () => {
  return (
    <div className={styles.bars}>
      <img alt='bar' src={barsLoader} className={styles.barsImage} />
    </div>
  );
};

export default Default;
