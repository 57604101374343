import {
  GET_USERS_LIST,
  GET_USERS_DETAILS,
  CLEAR_USER_REDUCER,
  SET_FILTER_STATUS,
  SET_NO_MORE_BLUE_DOTS,
  SET_MAX_REACHED,
  SET_NUM_BLUE_DOTS,
  SET_OUTSTANDING_BLUE,
  SET_USER_TAG_FILTER_MODAL_STATUS,
  SET_USER_SEARCH_TAGS,
  SET_USER_LOADING
} from '../../actions/types';

const initialState = {
  memberList: [],
  memberInfo: {},
  showFilters: false,
  noMoreBlueDots: false,
  maxReached: false,
  numBlueDots: 0,
  outstandingBlue: false,
  hasUserTagFilterModal: false,
  userSearchTags: undefined,
  loading: false
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS_LIST:
      return {
        ...state,
        memberList: action.payload
      };
    case GET_USERS_DETAILS:
      return {
        ...state,
        memberInfo: action.payload
      };
    case CLEAR_USER_REDUCER:
      return {
        ...state,
        memberList: [],
        memberInfo: {}
      };
    case SET_FILTER_STATUS:
      return {
        ...state,
        showFilters: action.payload
      };
    case SET_NO_MORE_BLUE_DOTS:
      return {
        ...state,
        noMoreBlueDots: action.payload
      };
    case SET_MAX_REACHED:
      return {
        ...state,
        maxReached: action.payload
      };
    case SET_NUM_BLUE_DOTS:
      return {
        ...state,
        numBlueDots: action.payload
      };
    case SET_OUTSTANDING_BLUE:
      return {
        ...state,
        outstandingBlue: action.payload
      };
    case SET_USER_TAG_FILTER_MODAL_STATUS:
      return {
        ...state,
        hasUserTagFilterModal: action.payload
      };
    case SET_USER_SEARCH_TAGS:
      return {
        ...state,
        userSearchTags: action.payload
      };
    case SET_USER_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}
