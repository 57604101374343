import { isEmpty, isDecimalNumber } from '../../../../../global/Helpers';

class AddExpenseValidation {
  /**
   * @desc Validate Sign Up Form
   * @param {*} param0
   */
  validateSubmit({ description, category, amount, date }) {
    let errors = {};

    if (isEmpty(description)) {
      errors.description = 'required';
    }
    if (isEmpty(category)) {
      errors.category = 'required';
    }
    if (isEmpty(amount)) {
      errors.amount = 'required';
    } else if (!isDecimalNumber(amount)) {
      errors.amount = 'invalid';
    }
    if (isEmpty(date)) {
      errors.date = 'required';
    }

    return {
      errors,
      isValid: isEmpty(errors)
    };
  }
}

const instance = new AddExpenseValidation();

export { AddExpenseValidation };
export default instance;
