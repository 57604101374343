import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import './ChatSection.scss';
import {
  getUsersMessages,
  sendMessage,
  internalMessage,
  importMessageToQDeck
} from '../../../../../services/messageService';
import MacroSection from '../../MacrosSection/MacrosSection.jsx';
import {
  setMessageList,
  setChatScroll,
  setMessageLoading,
  updateMessage,
  setDataMistakesModalStatus,
  setImportToQDeckModalStatus
} from '../../../../../actions/chatActions';
import { setSocketUsers } from '../../../../../actions/socketActions';
import { setMacroModalStatus } from '../../../../../actions/macroActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader, Modal } from '../../../../Common';
import Swal from 'sweetalert2';
import { updateSurveyResponse } from '../../../../../services/userService';
import DataMistakesSection from './DataMistakesSection.jsx';
import Lines from './Lines.jsx';
import _ from 'lodash';
import { faDesktop, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import ChatAttachment from './ChatAttachment.jsx';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import store from '../../../../../store/store';

const MessageMetadata = ({ data }) => {
  const messageIsUnhandled = (message) => _.isNil(_.get(message, 'id'));

  return (
    <>
      {messageIsUnhandled(data) && <span className='unhandledMessage'>Message did not get processed</span>}
      {(() => {
        switch (data.client_type) {
          case 'web':
            return <FontAwesomeIcon icon={faDesktop} title='web' />;
          case 'react native':
            return <FontAwesomeIcon icon={faMobileAlt} title='app' />;
          default:
            return null;
        }
      })()}
      {data.delivered && <FontAwesomeIcon icon={['fa', 'check-double']} />}
      {!data.delivered && <FontAwesomeIcon icon={['fa', 'check']} />}
      {data.bookkeeper && <span className='text'> {data.bookkeeper}</span>}
      <span className='text'>{moment(data.time).format('MM-DD-YYYY HH:mm:ss')}</span>
    </>
  );
};

const ChatSection = ({
  user,
  chat,
  socket,
  sendMessage,
  internalMessage,
  updateMessage,
  updateSurveyResponse,
  setChatScroll,
  setSocketUsers,
  setMacroModalStatus,
  setDataMistakesModalStatus,
  importMessageToQDeck,
  setImportToQDeckModalStatus
}) => {
  const [message, setMessage] = useState('');
  const [showImageViwer, setShowImageViwer] = useState(false);
  const [largeMedialUrl, setLargeMedialUrl] = useState('');
  const [messagesEnd, setMessagesEnd] = useState(undefined);

  useEffect(() => {
    const scrollToBottom = (chatScroll) => {
      if (messagesEnd) {
        messagesEnd.scrollIntoView({
          behavior: chatScroll.style,
          block: 'end',
          inline: 'nearest'
        });
        setChatScroll({ scroll: false, style: 'auto' });
      }
    };
    if (chat.chatScroll.scroll) {
      scrollToBottom(chat.chatScroll);
    }
  }, [chat.chatScroll, setChatScroll, messagesEnd]);

  /**
   * @description Open Image Viwer to see large image
   * @param {String} mediaUrl
   */
  const openImageViwer = (mediaUrl) => {
    setShowImageViwer(true);
    setLargeMedialUrl(mediaUrl);
  };

  /**
   * @description Close Image Viwer
   */
  const closeImageViwer = () => {
    setShowImageViwer(false);
    setLargeMedialUrl('');
  };

  /**
   * @desc On Message Send
   * @param {*} e
   * @param {*} userId
   */
  const onSendChatMessage = async (e, phone, internalOnly, twilio_number) => {
    e.preventDefault();
    const { memberInfo } = user;
    const memberPhone = memberInfo.phone;
    if (String(memberPhone) !== String(phone)) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: `Please reselect member.`
      });
    } else {
      let { users } = socket;
      const filteredUsers = users.filter((u) => u !== phone);

      const SMSobj = {
        id: memberInfo.id,
        phone,
        text: message,
        keeper_number: twilio_number,
        platform: memberInfo.platform,
        keeper_id: memberInfo.keeper_id
      };
      setMessage('');
      try {
        setSocketUsers(filteredUsers);
        if (internalOnly) {
          SMSobj.text = `[Internal only] ${SMSobj.message || 'User good to go'}`;
          await internalMessage(SMSobj);
        } else {
          await sendMessage(SMSobj);
        }
        setChatScroll({ scroll: true, style: 'smooth' });
      } catch (e) {
        console.log(e);
        Swal.fire({ type: 'error', title: 'Oops...', text: e });
      }
    }
  };

  const setMacroMessage = (macroMessage) => {
    setMessage(message + ' ' + macroMessage);
  };

  /**
   *
   * @description Creates an onClickHandler
   * @param {Object} message - the message object that the survey is attached to
   * @param {Number} response - the response number that the handler is attached to
   */
  const createHandleSurveyResponseClick = ({ message, response }) => async () => {
    if (message.surveyResponse === String(response)) {
      // Undoes the response
      await updateSurveyResponse({ surveyId: message.surveyId, surveyResponse: null });
      updateMessage(message.id, {
        surveyResponse: null
      });
    } else {
      // Sets the response
      await updateSurveyResponse({ surveyId: message.surveyId, surveyResponse: response });
      updateMessage(message.id, {
        surveyResponse: String(response)
      });
    }
  };

  const messageIsUnhandled = (message) => _.isNil(_.get(message, 'id'));

  const canReportDataMistake = (message) => {
    return messageIsUnhandled(message) || !['user', 'manual', undefined].includes(message.textType);
  };

  const showDataMistakesModal = (message) => {
    setDataMistakesModalStatus({ open: true, message });
  };

  const hideDataMistakesModal = () => {
    setDataMistakesModalStatus({ open: false });
  };

  const hideImportToQDeckModal = () => {
    setImportToQDeckModalStatus({ open: false });
  };

  const getUserDisplayName = (user) => {
    if (user?.firstname && user?.lastname) {
      return `${user.firstname} ${user.lastname}`;
    }
    if (user?.firstname) {
      return user.firstname;
    }
    return user.email;
  };

  const handleImportMessageToQDeck = async (message) => {
    if (!['user', 'manual'].includes(message.textType)) {
      throw new Error('Only user or agent messages can be imported to QDeck!');
    }

    const authorEmail = message.textType === 'user' ? user.memberInfo.email : store.getState().auth.user.email;
    const authorName =
      message.textType === 'user'
        ? getUserDisplayName(user.memberInfo)
        : getUserDisplayName(store.getState().auth.user);
    const authorType = message.textType === 'user' ? 'user' : 'bookkeeper';

    importMessageToQDeck({
      userId: user.memberInfo.id,
      authorEmail,
      authorName,
      authorType,
      text: message.body,
      timestamp: message.time
    });
  };

  const { messageList, loader } = chat;
  const { memberInfo } = user;

  const textFieldClass = classNames({
    txtField: true,
    warning: message.length > 400
  });
  const charLimitClass = classNames({
    rules: true,
    error: message.length > 400
  });

  return (
    <div className='chatSection'>
      <MacroSection setMacroMessage={setMacroMessage} />
      <Modal open={chat.hasDataMistakesModal} size='big' handleClose={hideDataMistakesModal}>
        <DataMistakesSection />
      </Modal>
      <Modal open={chat.importToQDeckModalOpen} size='medium' handleClose={hideImportToQDeckModal}>
        {chat.importToQDeckModalMessage}
      </Modal>
      {showImageViwer && <ImageViewer imageUrl={largeMedialUrl} closeViwer={() => closeImageViwer()} />}
      <div className='messageList'>
        {loader && (
          <div className='chatLoader'>
            <Loader name='bars' />
          </div>
        )}
        {messageList.map((data, index) => (
          <div
            className={classNames('messageItem', {
              unhandledMessage: messageIsUnhandled(data)
            })}
            key={`msg${index}`}>
            <div className={`message ${data.className}`}>
              {data.media === '' && <Lines body={data.body} />}
              {_.map(data?.attachments, (attachment, i) => (
                <ChatAttachment attachment={attachment} userId={memberInfo.id} key={i} />
              ))}
              {data.media !== '' && (
                <div>
                  <Lines className='imageText' body={data.body} />
                  <div className='sms-media'>
                    <img src={data.media} alt='sms' onClick={() => openImageViwer(data.media)} />
                  </div>
                </div>
              )}
              <details className='time'>
                <summary>
                  {(data.textType === 'user' || data.textType === 'manual') && (
                    <OverlayTrigger
                      placement='top'
                      overlay={<Tooltip className='import-to-qdeck-tooltip'>Import Message into QDeck</Tooltip>}>
                      {({ ref, ...triggerHandler }) => (
                        <button
                          className='import-to-qdeck-button'
                          type='button'
                          ref={ref}
                          {...triggerHandler}
                          onClick={() => {
                            handleImportMessageToQDeck(data);
                          }}>
                          📩
                        </button>
                      )}
                    </OverlayTrigger>
                  )}
                  <MessageMetadata data={data} />
                </summary>
                <table>
                  <tbody>
                    {data.textType && (
                      <tr>
                        <th>Text Type</th> <td>{data.textType}</td>
                      </tr>
                    )}
                    {data.client_type && (
                      <tr>
                        <th>Client Type</th>
                        <td>
                          {(() => {
                            switch (data.client_type) {
                              case 'web':
                                return 'web';
                              case 'react native':
                                return 'app';
                              default:
                                return null;
                            }
                          })()}
                        </td>
                      </tr>
                    )}
                    {data.origin && (
                      <tr>
                        <th>Origin</th>
                        <td>{data.origin}</td>
                      </tr>
                    )}
                    {data.location && (
                      <tr>
                        <th>Location</th>
                        <td>{data.location}</td>
                      </tr>
                    )}
                    {data.page_summary && (
                      <tr>
                        <th>Page Summary</th>
                        <td style={{ whiteSpace: 'pre-line' }}>{data.page_summary}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </details>
              {data.surveyId && (
                <div className='csatResponse'>
                  <button
                    className={classNames('happy', { pressed: data.surveyResponse === '1' })}
                    onClick={createHandleSurveyResponseClick({
                      message: data,
                      response: 1
                    })}>
                    Awesome!{' '}
                    <span role='img' aria-label='happy emoji'>
                      😊
                    </span>
                  </button>
                  <button
                    className={classNames('needsImprovement', { pressed: data.surveyResponse === '2' })}
                    onClick={createHandleSurveyResponseClick({
                      message: data,
                      response: 2
                    })}>
                    Needs improvement{' '}
                    <span role='img' aria-label='wrench emoji'>
                      🔧
                    </span>
                  </button>
                </div>
              )}
              {canReportDataMistake(data) && (
                <button className='reportDataMistake' onClick={() => showDataMistakesModal(data)}>
                  🐛
                </button>
              )}
            </div>
          </div>
        ))}
        <div
          style={{ float: 'left', clear: 'both' }}
          ref={(el) => {
            setMessagesEnd(el);
          }}
        />
      </div>
      <div className='messageInput'>
        <div className='inputSection'>
          <textarea
            type='text'
            name='message'
            className={textFieldClass}
            placeholder='Reply to user'
            autoComplete='false'
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
          {message.length === 0 ? (
            <button
              type='button'
              className='btn messageButtons'
              onClick={(e) => {
                onSendChatMessage(e, memberInfo.phone, true);
              }}>
              Dismiss
            </button>
          ) : (
            <button
              type='button'
              className='btn messageButtons'
              onClick={(e) => {
                onSendChatMessage(e, memberInfo.phone, false, memberInfo.twilio_number);
              }}
              disabled={message.length > 400 || message.length === 0}>
              Send
            </button>
          )}
          <button
            type='button'
            className='btn messageButtons'
            onClick={() => {
              setMacroModalStatus(true);
            }}>
            Macros
          </button>
        </div>
        <p className={charLimitClass}>Msg Limit: {message.length} / 400</p>
      </div>
    </div>
  );
};

ChatSection.propTypes = {
  user: PropTypes.object.isRequired,
  chat: PropTypes.object.isRequired,
  socket: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  chat: state.chat,
  socket: state.socket,
  macro: state.macro
});

const ConnectedChatSection = connect(mapStateToProps, {
  getUsersMessages,
  sendMessage,
  internalMessage,
  setMessageList,
  updateMessage,
  updateSurveyResponse,
  setChatScroll,
  setMessageLoading,
  setSocketUsers,
  setMacroModalStatus,
  setDataMistakesModalStatus,
  importMessageToQDeck,
  setImportToQDeckModalStatus
})(ChatSection);

export default ConnectedChatSection;

/**
 * Show large Image on Click
 */
class ImageViewer extends Component {
  state = {
    imageUrl: this.imageUrl
  };

  render() {
    const { imageUrl } = this.state;
    return (
      <div className='image-viwer'>
        <div className='close-viwer' onClick={() => this.closeViwer()}>
          <button>
            <FontAwesomeIcon icon={['fa', 'times']} />
          </button>
        </div>
        <div className='image-section'>
          <img src={imageUrl} alt='sms-media' />
        </div>
      </div>
    );
  }
}
