import _ from 'lodash';
import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { updateTags } from '../../../../../../services/tagService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StatusSelector, Loader } from '../../../../../Common';
import { trackActivity } from '../../../../../../utils/analytics/analyticsService';
import './Tags.scss';

// Generated default tag upon addition of a new tag row to the table
const EMPTY_TAG = {
  name: '',
  description: 'Custom tag created by an admin via Web Ops',
  keywords: [],
  is_custom: true,
  is_active: true
};

const STATUS_ACTIVE = 'Active';
const STATUS_INACTIVE = 'Inactive';

const MAX_KEYWORDS_PER_TAG = 7;

const Tags = ({ tags, successMsg, errorMsg, tagLoader, updateTags }) => {
  const columns = [
    {
      entryType: 'text',
      name: 'Name',
      getValue: (tag) => tag.name,
      setValue: (tag, name) => ({ ...tag, name }),
      required: true
    },
    {
      entryType: 'text',
      name: 'Keywords',
      getValue: (tag) => _.join(tag.keywords),
      setValue: (tag, keywords) => ({
        ...tag,
        keywords: _.split(keywords, ',').map((keyword) => _.trim(keyword))
      }),
      required: false
    },
    {
      entryType: 'status',
      name: 'Status',
      getValue: (tag) => (tag.is_active ? STATUS_ACTIVE : STATUS_INACTIVE),
      setValue: (tag, status) => ({ ...tag, is_active: status === STATUS_ACTIVE }),
      items: [STATUS_ACTIVE, STATUS_INACTIVE]
    }
  ];

  const [removedTags, updateRemovedTags] = useState([]);
  const [customTags, updateCustomTags] = useState([]);

  const [invalidationMessage, setInvalidationMessage] = useState(undefined);

  function onEntryChange(tagIdx, columnIdx, updatedValue) {
    const updatedTag = columns[columnIdx].setValue(customTags[tagIdx], updatedValue);
    updateCustomTags([...customTags.slice(0, tagIdx), updatedTag, ...customTags.slice(tagIdx + 1)]);
  }

  function onAddTag() {
    updateCustomTags([...customTags, EMPTY_TAG]);
  }

  function onRemoveTag(tagIdx) {
    updateRemovedTags([...removedTags, customTags[tagIdx]]);
    updateCustomTags([...customTags.slice(0, tagIdx), ...customTags.slice(tagIdx + 1)]);
  }

  function onSubmit(e) {
    e.preventDefault();
    // If user enters <keyword 1>,,,<keyword 2>, we want to ignore the two empty inbetween keywords
    const tagsWithoutEmptyKeywords = customTags.map((tag) => {
      return { ...tag, keywords: tag.keywords.filter((keyword) => !_.isEmpty(keyword)) };
    });
    updateTags(tagsWithoutEmptyKeywords, removedTags);
    updateRemovedTags([]);
    trackActivity('update custom tags', {
      updatedTags: customTags.map((tag) => tag.name)
    });
  }

  useEffect(() => {
    updateCustomTags(tags.filter((tag) => tag.is_custom));
  }, [tags]);

  useEffect(() => {
    const customTagNames = customTags.map((tag) => tag.name);
    const tagNamesUnique = new Set(customTagNames).size === customTagNames.length;

    if (!tagNamesUnique) {
      setInvalidationMessage(`Tag names must be unique`);
      return;
    }

    const nonCustomTagNames = tags.filter((tag) => !tag.is_custom).map((tag) => tag.name);
    const customTagsContainNonCustomTagName =
      _.difference(customTagNames, nonCustomTagNames).length !== customTagNames.length;
    if (customTagsContainNonCustomTagName) {
      setInvalidationMessage(`Tag names may not share names with non-custom tag names`);
      return;
    }

    const notTooManyKeywords = customTags.every((tag) => tag.keywords.length <= MAX_KEYWORDS_PER_TAG);
    if (!notTooManyKeywords) {
      setInvalidationMessage(`Each tag must have no more than ${MAX_KEYWORDS_PER_TAG} keywords`);
      return;
    }

    setInvalidationMessage(undefined);
  }, [tags, customTags]);

  return (
    <>
      <div className='modal-title'>
        <h5>Tags</h5>
      </div>
      <div className='add-expense'>
        {tagLoader && <Loader />}
        {successMsg && <div className='alert alert-success'>{successMsg}</div>}
        {errorMsg && <div className='alert alert-danger'>{errorMsg}</div>}
        {tags && (
          <div className='expense-content dflex'>
            <button className='btn grey' type='button' onClick={onAddTag}>
              <FontAwesomeIcon icon={['fa', 'plus']} />
            </button>

            <form onSubmit={onSubmit} className='dflexfull'>
              {columns.map((column, columnIdx) => (
                <Fragment key={`col_${columnIdx}_header`}>
                  {column.entryType === 'text' && <div className='description-rule-section'>{column.name}: </div>}
                  {column.entryType === 'status' && <div className='category-rule-section'>{column.name}: </div>}
                </Fragment>
              ))}
              {customTags.map((tag, tagidx) => {
                return (
                  <div key={`tag_${tagidx}`}>
                    {columns.map((column, columnIdx) => (
                      <Fragment key={`tag_${tagidx}_col_${columnIdx}`}>
                        {column.entryType === 'text' && (
                          <div className='description-rule-section'>
                            <input
                              type='text'
                              name='description'
                              value={column.getValue(tag)}
                              placeholder={`${column.name}...`}
                              required={column.required}
                              onChange={(e) => onEntryChange(tagidx, columnIdx, e.target.value)}
                              className='expense-input'
                            />
                          </div>
                        )}
                        {column.entryType === 'status' && (
                          <div className='category-rule-section fixcolorno'>
                            <div className='item expStatus'>
                              <StatusSelector
                                items={column.items}
                                value={column.getValue(tag)}
                                required={column.required}
                                onSelect={(item) => onEntryChange(tagidx, columnIdx, item)}
                              />
                            </div>
                          </div>
                        )}
                      </Fragment>
                    ))}
                    <div className='remove-rule-btn'>
                      <button className='btn grey' type='button' onClick={() => onRemoveTag(tagidx)}>
                        <FontAwesomeIcon icon={['fa', 'times']} />
                      </button>
                    </div>
                  </div>
                );
              })}
              <div className='expense-submit'>
                <button className='btn blue' type='submit' disabled={tagLoader || invalidationMessage !== undefined}>
                  Save
                </button>
              </div>
              <div className='validationText'>{invalidationMessage}</div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  tags: state.tag.tags,
  successMsg: state.tag.successMsg,
  errorMsg: state.tag.errorMsg,
  tagLoader: state.tag.tagLoader
});

const ConnectedTags = connect(mapStateToProps, {
  updateTags
})(Tags);

export default ConnectedTags;
