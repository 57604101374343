import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { googleLogin } from '../../services/authService';
import { Loader } from '../Common';
import { GoogleLogin } from '@react-oauth/google';
import './Login.scss';

class Login extends Component {
  state = {
    loading: false,
    errorMsg: ''
  };

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/dashboard');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/dashboard');
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  /**
   * @desc On Social Login Succeess
   * @param {*} user
   */
  onLoginSuccess(user) {
    this.props.googleLogin({ user });
  }

  /**
   * @desc On Login Failure
   * @param {*} err
   */
  onLoginFailure(err) {
    console.log(err);
  }

  render() {
    const { loading, errorMsg } = this.state;
    return (
      <div className='login'>
        <p className='title'>Sign in to Google to use Keeper Ops</p>
        <GoogleLogin onSuccess={(user) => this.onLoginSuccess(user)} onError={(err) => this.onLoginFailure(err)} />
        {loading && <Loader />}
        {errorMsg !== '' && <p className='errorBlock'>{errorMsg}</p>}
      </div>
    );
  }
}

Login.propTypes = {
  googleLogin: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

const ConnectedLogin = connect(mapStateToProps, { googleLogin })(Login);

export default ConnectedLogin;
