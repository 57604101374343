import {
  GET_USERS_LIST,
  GET_USERS_DETAILS,
  SET_FILTER_STATUS,
  SET_NO_MORE_BLUE_DOTS,
  SET_MAX_REACHED,
  SET_NUM_BLUE_DOTS,
  SET_OUTSTANDING_BLUE,
  SET_USER_SEARCH_TAGS,
  SET_USER_TAG_FILTER_MODAL_STATUS,
  SET_USER_LOADING
} from './types';

/**
 * @desc Set Users dispatch service
 * @param {*} users
 */
export const setUserList = (users) => {
  return {
    type: GET_USERS_LIST,
    payload: users
  };
};

/**
 * @description Update User Info in store
 * @param {Object} obj
 */
export const UpdateUserInfo = (obj) => (dispatch) => {
  dispatch(setUserInfo(obj));
};

// Set logged in user
export const setUserInfo = (decoded) => {
  return {
    type: GET_USERS_DETAILS,
    payload: decoded
  };
};

// Set filter status true or false
export const setFilterStatus = (payload) => {
  return {
    type: SET_FILTER_STATUS,
    payload
  };
};

// Set filter status true or false
export const setNoMoreBlueDots = (payload) => {
  return {
    type: SET_NO_MORE_BLUE_DOTS,
    payload
  };
};

// Set filter status true or false
export const setMaxReached = (payload) => {
  return {
    type: SET_MAX_REACHED,
    payload
  };
};

// set status on outstanding blue dots
export const setOutstandingBlue = (payload) => {
  return {
    type: SET_OUTSTANDING_BLUE,
    payload
  };
};

// Set filter status true or false
export const setNumBlueDots = (payload) => {
  return {
    type: SET_NUM_BLUE_DOTS,
    payload
  };
};

export const setHasUserTagFilterModal = (payload) => {
  return {
    type: SET_USER_TAG_FILTER_MODAL_STATUS,
    payload
  };
};

export const setUserSearchTags = (payload) => {
  return {
    type: SET_USER_SEARCH_TAGS,
    payload
  };
};

export const setUserLoading = (payload) => {
  return {
    type: SET_USER_LOADING,
    payload
  };
};
