import { GET_USERS_RULE_LIST, SET_RULE_LOADER, SET_RULE_MODAL_STATUS, UPDATE_RULE_RES_MSG } from './types';

/**
 * @desc Set Users rule list dispatch service
 * @param {*} expenses
 */
export const setRuleist = (rules) => {
  return {
    type: GET_USERS_RULE_LIST,
    payload: rules
  };
};

/**
 * @desc Set rule modal status like open/close
 * @param {Boolean} payload
 */
export const setRuleModalStatus = (payload) => {
  return {
    type: SET_RULE_MODAL_STATUS,
    payload
  };
};

/**
 * @desc Set Loader
 * @param {*} obj
 */
export const setRuleLoader = (payload) => {
  return {
    type: SET_RULE_LOADER,
    payload
  };
};

/**
 * @desc Update rule Res messages
 */
export const setRuleResMsg = (payload) => {
  const { success, error } = payload;
  const resObj = {
    success: success !== undefined ? success : '',
    error: error !== undefined ? error : ''
  };
  return {
    type: UPDATE_RULE_RES_MSG,
    payload: resObj
  };
};
