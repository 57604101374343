import React from 'react';
import styles from './Default.module.scss';

const Default = ({ type }) => {
  let loaderType;
  if (type === undefined) {
    loaderType = styles.normal;
  } else if (type === 'page') {
    loaderType = styles.page;
  } else {
    loaderType = styles.normal;
  }

  return (
    <div className={loaderType}>
      <div className={styles.loader + ' pre-loader'}>
        <div className='preloader-wrapper active m-c'>
          <div className='spinner-layer spinner-blue-only'>
            <div className='circle-clipper left'>
              <div className='circle' />
            </div>
            <div className='gap-patch'>
              <div className='circle' />
            </div>
            <div className='circle-clipper right'>
              <div className='circle' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Default;
