import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { updateUserTags } from '../../../../../../services/userService';
import { trackActivity } from '../../../../../../utils/analytics/analyticsService';
import { fetchTags } from '../../../../../../services/tagService';
import _ from 'lodash';

const UserTags = ({ user, tags, fetchTags, updateUserTags }) => {
  const [allPossibleTags, setAllPossibleTags] = useState([]);
  const [currentTags, setCurrentTags] = useState([]);

  useEffect(() => {
    if (_.isEmpty(tags)) {
      fetchTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags.length]);

  useEffect(() => {
    if (tags) {
      setAllPossibleTags(
        tags.map((tag) => ({
          ...tag,
          value: tag.id,
          label: tag.name
        }))
      );
    }
  }, [tags]);

  useEffect(() => {
    const currentTagIds = user.memberInfo.tags;
    setCurrentTags(allPossibleTags.filter((tag) => currentTagIds.includes(tag.id)));
  }, [allPossibleTags, user.memberInfo]);

  const onSelectTag = async ({ selectedTags }) => {
    const { id: userId, phone } = user.memberInfo;

    try {
      await updateUserTags({
        tagIds: selectedTags.map(({ value }) => value),
        userId,
        userPhone: phone
      });
      trackActivity('updated tags', {
        updatedTags: selectedTags.map(({ label }) => label),
        userPhone: phone
      });
    } catch (error) {
      console.error({ error }, 'not able to update tags');
    }
  };

  return (
    <Select
      isMulti={true}
      options={allPossibleTags}
      value={currentTags}
      onChange={(selectedTags) => onSelectTag({ selectedTags })}
      backspaceRemovesValue={false}
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  tags: state.tag.tags
});

const ConnectedUserTags = connect(mapStateToProps, {
  updateUserTags,
  fetchTags
})(UserTags);

export default ConnectedUserTags;
