import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { addBlueDots, getUserList } from '../../../services/userService';
import { setUserList, setHasUserTagFilterModal } from '../../../actions/userActions';
import { Loader, Modal } from '../../Common';
import UserTagFilter from './UserTagFilter.jsx';
import './BlueDotBtn.scss';

class BlueDotBtn extends Component {
  state = {
    loading: false
  };

  async addBlueDots() {
    this.setState({ loading: true });
    await this.props.addBlueDots();
    const { noMoreBlueDots, maxReached, outstandingBlue } = this.props.user;
    this.setState({ loading: false });

    if (outstandingBlue) {
      Swal.fire({
        type: 'error',
        title: 'Blue dots left',
        text: `Please answer all blue dots before requesting more 🙏`
      });
    }

    if (noMoreBlueDots && !outstandingBlue) {
      Swal.fire('Woohoo!', 'No more blue dots!', 'success');
    }
    if (maxReached) {
      Swal.fire({
        type: 'error',
        title: 'Max blue dots reached',
        text: `Take a break!`
      });
    }
  }

  render() {
    const { memberList, numBlueDots } = this.props.user;
    const { loading } = this.state;
    const admin = this.props.auth.user.admin === 1;
    const cursor = admin ? 'default' : 'pointer';
    const userText = memberList.length === 1 ? 'user' : 'users';

    return (
      <>
        <div className='blue-dot'>
          {loading && (
            <div className='userListLoader'>
              <Loader name='bars' />
            </div>
          )}

          <div className='user-count'>
            {admin && (
              <div>
                {memberList.length} {userText}
              </div>
            )}
            {!admin && (
              <div>
                {memberList.length} / {numBlueDots}{' '}
              </div>
            )}
            {admin && (
              <button
                className={this.props.userSearchTags ? 'btn filters-active-btn' : 'btn filters-inactive-btn'}
                type='button'
                onClick={() => this.props.setHasUserTagFilterModal(true)}>
                <div className='btn-label'>Filter</div>
              </button>
            )}
          </div>
          <button
            className='blue-dot-btn'
            type='button'
            style={{ cursor }}
            disabled={admin}
            onClick={() => this.addBlueDots()}>
            {admin && <div className='btn-label'>Admin</div>}
            {!admin && (
              <div className='btn-content'>
                <div className='btn-label'>Add</div> <div className='itemNotification' />
              </div>
            )}
          </button>
        </div>
        <Modal
          open={this.props.hasUserTagFilterModal}
          size='big'
          handleClose={() => this.props.setHasUserTagFilterModal(false)}>
          <UserTagFilter />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  hasUserTagFilterModal: state.user.hasUserTagFilterModal,
  userSearchTags: state.user.userSearchTags
});

const ConnectedBlueDotBtn = connect(mapStateToProps, {
  getUserList,
  setUserList,
  addBlueDots,
  setHasUserTagFilterModal
})(BlueDotBtn);

export default ConnectedBlueDotBtn;
